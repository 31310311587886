import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center header-icon-notification-list"},[_c(VMenu,{attrs:{"close-on-content-click":true,"location":"end"},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs, on }){return [_c(VBadge,{attrs:{"content":_vm.countNotifications.length,"value":_vm.countNotifications.length == 0 ? false : true,"max":"10","overlap":"","color":"red darken-2","offset-x":"20","offset-y":"20","text-color":"black"}},[_c(VAvatar,_vm._g(_vm._b({staticClass:"header-buttons",attrs:{"icon":""},on:{"click":_vm.dispatchGtmEvent}},'v-avatar',attrs,false),on),[_c(VIcon,[_vm._v(" mdi-bell-circle-outline ")])],1)],1)]}}])},[_c(VCard,{attrs:{"min-width":"500"}},[_c(VList,{attrs:{"dense":"","two-lines":""}},[_c(VSubheader,{attrs:{"color":"black"}},[_vm._v(_vm._s(_vm.$tt.uppercase(_vm.$t('labels.notifications'))))]),_c(VDivider),_c(VListItemGroup,{staticClass:"v-lilst-scroll"},_vm._l((_vm.list),function(item,index){return _c(VListItem,{key:index,on:{"click":function($event){return _vm.markAsRead(item._id, item.route)}}},[_c('f-notification-list-item',{attrs:{"notification":item}})],1)}),1)],1),(_vm.countNotifications.length != 0)?[_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"large":"","color":"primary","elevation":"0"},on:{"click":function($event){return _vm.clearNotifications()}}},[_vm._v(_vm._s(_vm.$t('notifications.clear')))])],1)]:_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }