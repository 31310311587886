import { KEYS as COLOR_SCHEME } from '../data/templateColorSchemes';

export default [
  {
    path: '/courses',
    name: 'course-list',
    component: function() {
      return import(
        /* webpackChunkName: "course-list" */ '../views/course/List.vue'
      );
    },
  },
  {
    path: '/courses/my-courses',
    name: 'course-list-my-courses',
    component: function() {
      return import(
        /* webpackChunkName: "course-list" */ '../views/course/ListMyCourses.vue'
      );
    },
  },
  {
    path: '/courses/my-certificates',
    name: 'course-list-my-certificates',
    component: function() {
      return import(
        /* webpackChunkName: "course-list" */ '../views/course/ListMyCertificates.vue'
      );
    },
  },
  {
    path: '/course/create',
    name: 'course-create',
    component: function() {
      return import(
        /* webpackChunkName: "course-create" */ '../views/course/CreateEdit.vue'
      );
    },
  },
  {
    path: '/course/edit/:id',
    name: 'course-edit',
    component: function() {
      return import(
        /* webpackChunkName: "course-edit" */ '../views/course/CreateEdit.vue'
      );
    },
  },
  {
    path: '/course/view/:id',
    name: 'course-view',
    component: function() {
      return import(
        /* webpackChunkName: "course-view" */ '../views/course/View.vue'
      );
    },
  },

  // LESSONS
  // create
  {
    path: '/course/:courseid/lesson/create',
    name: 'course-linear-lesson-create',
    component: function() {
      return import(
        /* webpackChunkName: "course-linear-lesson-create" */ '../views/course/lesson/CreateEdit.vue'
      );
    },
  },
  {
    path: '/course/:courseid/module/:moduleid/lesson/create',
    name: 'course-modular-lesson-create',
    component: function() {
      return import(
        /* webpackChunkName: "course-modular-lesson-create" */ '../views/course/lesson/CreateEdit.vue'
      );
    },
  },

  // edit
  {
    path: '/course/:courseid/lesson/edit/:lessonid',
    name: 'course-linear-lesson-edit',
    component: function() {
      return import(
        /* webpackChunkName: "course-linear-lesson-edit" */ '../views/course/lesson/CreateEdit.vue'
      );
    },
  },
  {
    path: '/course/:courseid/module/:moduleid/lesson/edit/:lessonid',
    name: 'course-modular-lesson-edit',
    component: function() {
      return import(
        /* webpackChunkName: "course-lesson-edit" */ '../views/course/lesson/CreateEdit.vue'
      );
    },
  },

  // view
  {
    path: '/course/:courseid/lesson/view/:lessonid',
    name: 'course-linear-lesson-view',
    component: function() {
      return import(
        /* webpackChunkName: "course-lesson-view" */ '../views/course/lesson/View.vue'
      );
    },
    meta: {
      colorScheme: COLOR_SCHEME.BLUE,
    },
  },
  {
    path: '/course/:courseid/module/:moduleid/lesson/view/:lessonid',
    name: 'course-modular-lesson-view',
    component: function() {
      return import(
        /* webpackChunkName: "course-lesson-view" */ '../views/course/lesson/View.vue'
      );
    },
    meta: {
      colorScheme: COLOR_SCHEME.BLUE,
    },
  },
  {
    path: '/clear-content/course/:courseid/lesson/view/:lessonid',
    name: 'clear-content-linear',
    component: function() {
      return import(
        /* webpackChunkName: "clear-content-linear" */ '../views/course/lesson/ClearView.vue'
      );
    },
    meta: {
      layout: 'clear',
    },
  },
  {
    path:
      '/clear-content/course/:courseid/module/:moduleid/lesson/view/:lessonid',
    name: 'clear-content-modular',
    component: function() {
      return import(
        /* webpackChunkName: "clear-content-modular" */ '../views/course/lesson/ClearView.vue'
      );
    },
    meta: {
      layout: 'clear',
    },
  },

  // MODULES
  {
    path: '/course/:courseid/module/create',
    name: 'course-module-create',
    component: function() {
      return import(
        /* webpackChunkName: "course-module-create" */ '../views/course/module/CreateEdit.vue'
      );
    },
  },
  {
    path: '/course/:courseid/module/edit/:moduleid',
    name: 'course-module-edit',
    component: function() {
      return import(
        /* webpackChunkName: "course-module-edit" */ '../views/course/module/CreateEdit.vue'
      );
    },
  },
  // {
  //   path: '/course/:courseid/module/view/:moduleid',
  //   name: 'course-module-view',
  //   component: function() {
  //     return import(
  //       /* webpackChunkName: "course-module-view" */ '../views/course/module/ViewRemove.vue'
  //     );
  //   },
  // },
].map(item => {
  if (!item['meta'] || !item['meta']['menu']) {
    if (!item['meta']) {
      item['meta'] = { menu: 'courses' };
    } else {
      item['meta']['menu'] = 'courses';
    }
  }
  return item;
});
