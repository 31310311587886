<template>
  <div class="lms-data-table-btn" @click="click">
    <slot />
  </div>
</template>

<script lang="js">
export default {
	methods: {
		click() {
			this.$emit('click')
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/base/variables";

.lms-data-table-btn {
  display: inline-block;
  margin-left: 6px;
  cursor: pointer;

  .v-icon {
    font-size: 20px;
    padding: 5px;
  }

  &:hover {
    .v-icon {
      color: $primary;
    }
  }
}
</style>
