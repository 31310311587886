<template>
  <div class="lang-selector">
    <v-tabs v-model="selectedLang" background-color="transparent" right>
      <v-tab v-for="item in langs" :key="item">
        {{ item }}
      </v-tab>
    </v-tabs>
  </div>
</template>

<script>
export default {
  props: {
    langs: {
      type: Array,
    },
  },
  computed: {
    selectedLang: {
      get() {
        return this.$store.getters['system/selectedInputsLang'];
      },
      set(value) {
        this.$store.commit('system/selectInputsLang', value);
      },
    },
  },
};
</script>
