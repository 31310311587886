import apiUtils from '../helpers/api_utils';

const PAGES = {
  DATA_SELECT: 'DATA_SELECT',
  REVIEW: 'REVIEW',
  CONFIRMATION: 'CONFIRMATION',
};

const store = {
  namespaced: true,
  state: () => ({
    selected: [],
    certificateData: {},
    pageControl: PAGES.DATA_SELECT,
  }),
  getters: {
    pageControl({ pageControl }) {
      return pageControl;
    },
  },
  actions: {
    pageReview({ state }) {
      state.pageControl = PAGES.REVIEW;
    },
    pageSelect({ state }) {
      state.pageControl = PAGES.DATA_SELECT;
    },
    async generate({ state, rootGetters, dispatch }) {
      const token = rootGetters['auth/userToken'];
      const result = await apiUtils.post(
        'user/custom-certificates',
        {
          users: state.selected.map(item => item._id),
          certificate: state.certificateData,
        },
        token
      );
      state.pageControl = PAGES.CONFIRMATION;

      dispatch('clear', false);
    },
    async getCertificatesData({ state, rootGetters, dispatch, rootState }) {
      const authUser = rootGetters['auth/user'];
      const token = authUser.token;
      const sessionUserCertificates = authUser.certificates;
      const coursesIds = Array.isArray(sessionUserCertificates)
        ? sessionUserCertificates.map(item => item.courseID)
        : [];
      const certificatesData = await apiUtils.post(
        'user/get_certificates_data',
        {
          coursesIds,
        },
        token
      );

      return certificatesData.body;
    },
    clear({ state }, full = true) {
      state.selected = [];
      state.certificateData = {};

      if (full) state.pageControl = PAGES.DATA_SELECT;
    },
  },
};

export default store;
