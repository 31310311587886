export default [
  {
    path: '/reports',
    name: 'report-list',
    component: function() {
      return import(
        /* webpackChunkName: "course-list" */ '../views/report/Reports.vue'
      );
    },
  },
  // {
  //   path: '/reports/courses',
  //   name: 'report-list-courses',
  //   component: function() {
  //     return import(
  //       /* webpackChunkName: "course-list" */ '../views/report/Reports.vue'
  //     );
  //   },
  // },
  // {
  //   path: '/reports/students',
  //   name: 'report-list-students',
  //   component: function() {
  //     return import(
  //       /* webpackChunkName: "course-list" */ '../views/report/Reports.vue'
  //     );
  //   },
  // },
  {
    path: '/:route/reports/by-course/:id',
    name: 'report-course-view',
    component: function() {
      return import(
        /* webpackChunkName: "course-list" */ '../views/report/CourseView.vue'
      );
    },
  },
  {
    path: '/:route/reports/by-student/:id',
    name: 'report-student-view',
    component: function() {
      return import(
        /* webpackChunkName: "student-list" */ '../views/report/StudentView.vue'
      );
    },
  },
].map(item => {
  if (!item['meta']) {
    item['meta'] = { menu: 'reports' };
  }
  return item;
});
