import store from '../store';
import apiUtils from '../helpers/api_utils';

const ROUTE = 'exam';

export const screenNames = {
  ApprovedLock: 'approved-lock-screen',
  AttemptsLock: 'attempts-lock-screen',
  Exam: 'exam-screen',
  Loading: 'load-screen',
  RequirementLock: 'requirement-lock-screen',
  Result: 'result-screen',
  Start: 'start-screen',
  TimeLock: 'time-lock-screen',
};

export const loadInfoData = async ids => {
  const TOKEN = store.getters['auth/userToken'];

  return (await apiUtils.get(`${ROUTE}/load-info-data`, ids, TOKEN)).body;
};
