import { KEYS as userTypes } from '../data/userTypes';

export default {
  data: () => ({
    userTypes,
  }),
  computed: {
    isLoading() {
      return this.$store.getters['system/isLoading'];
    },
  },
};
