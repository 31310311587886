import moment from 'moment'

const dateFormatter = {
  datetime(value) {
    return moment(value).format('DD/MM/YYYY HH:mm:ss')
  },
}

export default {
  install(Vue, options) {
    Vue.prototype.$df = dateFormatter
  }
}
