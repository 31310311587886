import apiUtils from '../helpers/api_utils';
import * as countryService from '../services/countries';
import * as distributorService from '../services/distributors';
import * as trainingTypeService from '../services/trainingTypes';
import * as userCategoryService from '../services/userCategories';
import * as settingService from '../services/settings';

const store = {
  namespaced: true,
  state: () => ({
    list: [],

    dataValues: {},

    country: {
      id: null,
      list: [],
      listCount: 0,
      view: {},
    },

    distributor: {
      list: [],
      view: {},
    },

    trainingType: {
      list: [],
      view: {},
    },

    userCategory: {
      list: [],
      view: {},
    },
  }),
  mutations: {
    set(state, list) {
      state.list = list;
    },
    update(state, setting) {
      state.list = state.list.map(item => {
        if (item.name === setting.name) {
          return { ...item, value: setting.value };
        }
        return item;
      });
    },
  },
  actions: {
    async createOrUpdate({ commit, rootGetters }, setting) {
      const token = rootGetters['auth/userToken'];
      const data = await apiUtils.post(
        'setting/create-or-update',
        setting,
        token
      );
      const success = +data.status === 1;
      if (success) {
        commit('update', setting);
      }
      return success;
    },
    async getData({ state, commit }, settingName) {
      commit('system/loading', true, { root: true });

      const result = await settingService.get(settingName);
      state.dataValues[settingName] = result;

      commit('system/loading', false, { root: true });
    },
    async saveData({ state }, settingName) {
      const result = await settingService.save({
        name: settingName,
        value: state.dataValues[settingName],
      });
    },
    async list({ commit, rootGetters }) {
      const token = rootGetters['auth/userToken'];
      const data = await apiUtils.get('setting/list', {}, token);
      commit('set', data.body);
      return data.body;
    },
    async labelSellerOnEvething({ commit, rootGetters }) {
      commit('system/loading', true, { root: true });

      const token = rootGetters['auth/userToken'];
      const data = await apiUtils.get(
        'setting/label-seller-on-evething',
        {},
        token
      );

      commit('system/loading', false, { root: true });
    },
    async clearDBCache({ commit, rootGetters }) {
      commit('system/loading', true, { root: true });

      const token = rootGetters['auth/userToken'];
      const data = await apiUtils.get('setting/clear-db-cache', {}, token);

      commit('system/loading', false, { root: true });
    },
    async fixInvalidDate({ commit, rootGetters }) {
      commit('system/loading', true, { root: true });

      const token = rootGetters['auth/userToken'];
      const data = await apiUtils.get('setting/fix-invalid-date', {}, token);

      commit('system/loading', false, { root: true });
    },

    // COUNTRY
    async listCountries({ commit, state }, filters) {
      commit('system/loading', true, { root: true });

      const result = await countryService.list(filters);
      state.country.list = result.items;
      state.country.listCount = result.count;

      commit('system/loading', false, { root: true });
    },
    async getCountry({ commit, state }, countryID) {
      commit('system/loading', true, { root: true });

      state.country.view = await countryService.get(countryID);

      commit('system/loading', false, { root: true });
    },
    async saveCountry({ state }) {
      countryService.save(state.country.view);

      return true;
    },

    // Distributors
    async listDistributors({ commit, state }, countryID) {
      commit('system/loading', true, { root: true });

      const result = await distributorService.list(countryID);
      state.distributor.list = result;

      commit('system/loading', false, { root: true });
    },
    async getDistributor({ commit, state }, payload) {
      commit('system/loading', true, { root: true });

      const result = await distributorService.get(
        payload.countryID,
        payload.distributorID
      );
      state.distributor.view = result;

      commit('system/loading', false, { root: true });
    },
    async saveDistributor({ state }, countryID) {
      await distributorService.save(countryID, state.distributor.view);
      state.distributor.view = {};

      return true;
    },
    async removeDistributor({ state }, payload) {
      distributorService.remove(payload.countryID, payload.distributorID);
      const index = state.userCategory.list.map(item => item.id).indexOf(id);
      state.userCategory.list.splice(index, 1);
      userCategoryService.save(state.userCategory.list);

      return true;
    },

    // Training Types
    async listTrainingTypes({ commit, state }) {
      commit('system/loading', true, { root: true });

      const result = await trainingTypeService.list();
      state.trainingType.list = result;

      commit('system/loading', false, { root: true });
    },
    async getTrainingType({ state, commit }, id) {
      commit('system/loading', true, { root: true });

      state.trainingType.view = state.trainingType.list.find(
        item => item.id == id
      );

      commit('system/loading', false, { root: true });

      if (typeof state.trainingType.view == 'undefined') {
        return false;
      }

      return true;
    },
    async saveTrainingType({ state }) {
      const item = state.trainingType.view;
      if (typeof item.id == 'undefined') {
        state.trainingType.list.push(item);
      }
      trainingTypeService.save(state.trainingType.list);

      return true;
    },
    async removeTrainingType({ state }, id) {
      const index = state.trainingType.list.map(item => item.id).indexOf(id);
      state.trainingType.list.splice(index, 1);
      trainingTypeService.save(state.trainingType.list);

      return true;
    },

    // User category
    async listUserCategory({ commit, state }) {
      commit('system/loading', true, { root: true });

      const result = await userCategoryService.list();
      state.userCategory.list = result;

      commit('system/loading', false, { root: true });
    },
    async getUserCategory({ state, commit }, id) {
      commit('system/loading', true, { root: true });
      state.userCategory.view = state.userCategory.list.find(
        item => item.id == id
      );

      if (typeof state.userCategory.view == 'undefined') {
        return false;
      }
      commit('system/loading', false, { root: true });

      return true;
    },
    async saveUserCategory({ state }) {
      const item = state.userCategory.view;
      if (typeof item.id == 'undefined') {
        state.userCategory.list.push(item);
      }
      userCategoryService.save(state.userCategory.list);

      return true;
    },
    async removeUserCategory({ state }, id) {
      const index = state.userCategory.list.map(item => item.id).indexOf(id);
      state.userCategory.list.splice(index, 1);
      userCategoryService.save(state.userCategory.list);

      return true;
    },

    async init({ commit }) {
      commit('system/loading', true, { root: true });

      await settingService.init();

      commit('system/loading', false, { root: true });
    },
    clear({ state }, fields) {
      fields.forEach(field => {
        switch (field) {
          case 'dataValues':
            state.dataValues = {};
            break;
        }
      });
    },
  },
};

export default store;
