<template>
  <div>
    <v-app-bar v-if="!$store.state.auth.isLogged" app elevation="0">
      <span class="logo">Fluke Academy Latam</span>
      <em class="product"> e-learning</em>
    </v-app-bar>

    <v-app-bar
      v-if="$store.state.auth.isLogged"
      app
      elevation="0"
      :style="{ zIndex: 20 }"
      class="f-app-bar"
    >
      <!-- {{ resizing }}
      {{ screenSize }} -->
      <div class="f-app-bar-content">
        <div class="left">
          <f-header-logo />
          <span class="appbar-separator">|</span>
          <f-header-menu />
        </div>

        <div class="right">
          <f-notification-list v-if="!isLocked" />
          <f-header-avatar />
          <mobile-menu-btn />
        </div>
      </div>

      <div class="loader" v-if="isLoading">
        <v-progress-linear
          indeterminate
          color="primary"
          background-color="black"
          height="2px"
        />
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import FHeaderAvatar from '../shared/HeaderAvatarButton.vue';
import FHeaderLogo from './HeaderLogo.vue';
import FHeaderMenu from './HeaderMenu.vue';
import FNotificationList from '../../views/notification/List.vue';
import MobileMenuBtn from './MobileMenuBtn.vue';

export default {
  components: {
    FHeaderAvatar,
    FHeaderLogo,
    FHeaderMenu,
    FNotificationList,
    MobileMenuBtn,
  },
  data: () => ({
    scrolling: 'nope',
    stimeout: null,
    resizing: 'nope',
    rtimeout: null,
    // screenSize: 0,
  }),
  computed: {
    isLocked() {
      return this.$store.state.system.isLocked;
    },
    screenSize() {
      return this.$store.state.system.screenSizeCtl.size.current;
    },
  },
  mounted() {
    this.$store.state.system.screenSizeCtl.size.current = window.innerHeight;
    this.$store.state.system.screenSizeCtl.size.big = window.innerHeight;
    this.$store.state.system.screenSizeCtl.size.small = window.innerHeight;
    // document.addEventListener('scroll', event => {
    //   this.scrolling = 'yeap';
    //   clearInterval(this.stimeout);
    //   this.stimeout = setTimeout(() => {
    //     this.scrolling = 'nope';
    //   }, 500);
    // });
    window.addEventListener('resize', event => {
      this.resizing = 'yeap';
      clearInterval(this.rtimeout);
      this.rtimeout = setTimeout(() => {
        this.resizing = 'nope';
        // this.screenSize = window.innerHeight;
        if (
          this.$store.state.system.screenSizeCtl.size.big < window.innerHeight
        ) {
          this.$store.state.system.screenSizeCtl.size.big = window.innerHeight;
          this.$store.state.system.screenSizeCtl.gap =
            this.$store.state.system.screenSizeCtl.size.big -
            this.$store.state.system.screenSizeCtl.size.small;
        }
        if (
          this.$store.state.system.screenSizeCtl.size.small > window.innerHeight
        ) {
          this.$store.state.system.screenSizeCtl.size.small =
            window.innerHeight;
        }
      }, 50);
    });
  },
};
</script>
