<template>
  <div v-if="!isLocked" class="menu-container">
    <a
      v-for="(item, index) in menuItems"
      :key="item.index"
      :class="[
        isActive(item.target) ? 'active' : '',
        `menu-header-anchor ${item.target}`,
        'muted',
      ]"
      @click="goTo(item.target)"
      >{{ $tt.capitalize($t(item.label)) }}</a
    >
  </div>
</template>

<script>
import { UserType } from '../../helpers/user_utils';

export default {
  computed: {
    isLocked() {
      return this.$store.state.system.isLocked;
    },
    menuItems() {
      let items = [];

      const userType = this.$store.getters['auth/user'].type;
      switch (userType) {
        case UserType.Admin:
          items = [
            { label: 'labels.courses', target: 'course-list' },
            { label: 'labels.users', target: 'user-student-list' },
            { label: 'labels.teams', target: 'team-list' },
            { label: 'labels.learningPaths', target: 'learning-paths-list' },
            { label: 'labels.reports', target: 'report-list' },
            { label: 'labels.settings', target: 'settings' },
          ];
          break;
        case UserType.TSM:
          items = [
            { label: 'labels.courses', target: 'course-list' },
            { label: 'labels.users', target: 'user-student-list' },
            { label: 'labels.teams', target: 'team-list' },
            { label: 'labels.learningPaths', target: 'learning-paths-list' },
            { label: 'labels.reports', target: 'report-list' },
          ];
          break;
        case UserType.Master:
          items = [
            { label: 'labels.courses', target: 'course-list' },
            { label: 'labels.users', target: 'user-student-list' },
            { label: 'labels.teams', target: 'team-list' },
            { label: 'labels.learningPaths', target: 'learning-paths-list' },
            { label: 'labels.reports', target: 'report-list' },
          ];
          break;
        case UserType.Student:
          items = [
            { label: 'labels.courses', target: 'course-list' },
            { label: 'labels.learningPaths', target: 'learning-paths-list' },
          ];
          break;
        default:
          items = [
            { label: 'labels.courses', target: 'course-list' },
            { label: 'labels.learningPaths', target: 'learning-paths-list' },
          ];
      }

      return items;
    },
  },
  methods: {
    isActive(route) {
      try {
        var targetMeta = '',
          currentMeta = '';

        //target  meta
        const targetObjRoute = this.$router.options.routes.find(
          item => item.name == route
        );
        if (targetObjRoute.meta && targetObjRoute.meta.menu) {
          targetMeta = targetObjRoute.meta.menu;
        }

        // current meta
        currentMeta = this.$route.meta.menu;

        return currentMeta == targetMeta;
      } catch (e) {
        return false;
      }
    },
    goTo(routeName) {
      if (routeName == this.$route.name) return;
      this.$router.push({
        name: routeName,
      });
      this.$gtm.trackEvent({
        category: 'header-menu',
        event: 'click',
        action: 'header-menu-click',
        label: 'header-menu',
        value: `menu-${routeName}`,
      });
    },
  },
};
</script>
