<template>
  <div class="text-center header-icon-notification-list">
    <v-menu :close-on-content-click="true" location="end">
      <template v-slot:activator="{ attrs, on }">
        <v-badge
          :content="countNotifications.length"
          :value="countNotifications.length == 0 ? false : true"
          max="10"
          overlap
          color="red darken-2"
          offset-x="20"
          offset-y="20"
          text-color="black"
        >
          <v-avatar
            class="header-buttons"
            icon
            v-bind="attrs"
            v-on="on"
            @click="dispatchGtmEvent"
          >
            <v-icon>
              mdi-bell-circle-outline
            </v-icon>
          </v-avatar>
        </v-badge>
      </template>

      <v-card min-width="500">
        <v-list dense two-lines>
          <v-subheader color="black">{{
            $tt.uppercase($t('labels.notifications'))
          }}</v-subheader>
          <v-divider></v-divider>
          <v-list-item-group class="v-lilst-scroll">
            <v-list-item
              v-for="(item, index) in list"
              :key="index"
              @click="markAsRead(item._id, item.route)"
            >
              <f-notification-list-item
                :notification="item"
              ></f-notification-list-item>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <template v-if="countNotifications.length != 0">
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              color="primary"
              elevation="0"
              @click="clearNotifications()"
              >{{ $t('notifications.clear') }}</v-btn
            >
          </v-card-actions>
        </template>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import NotificationListItem from '../../components/NotificationListItem.vue';
import notificationUtils from '../../helpers/notification_utils';

export default {
  components: {
    'f-notification-list-item': NotificationListItem,
  },
  data: () => ({
    list: [],
  }),
  methods: {
    dispatchGtmEvent() {
      this.$gtm.trackEvent({
        category: 'header-menu',
        event: 'click',
        action: 'header-menu-click',
        label: 'menu-notification',
      });
    },
    async get() {
      const result = await this.$store.dispatch('notification/list');
      const list = notificationUtils.formatData(result, this.$i18n.locale);
      this.list = list;
    },
    onChangeLoggedIn(loggedIn) {
      if (loggedIn) {
        this.get();
        clearInterval(this.notificationInterval);
        this.notificationInterval = setInterval(() => {
          this.get();
        }, 2000 * 30);
      } else {
        if (this.notificationInterval) {
          clearInterval(this.notificationInterval);
        }
      }
    },
    async markAsRead(id, route) {
      if (id && route) {
        await this.$store.dispatch('notification/markAsRead', id);
        this.notificationView(route);

        this.$gtm.trackEvent({
          category: 'notification',
          event: 'click',
          action: 'notification-click',
          label: 'notification-open',
        });
      }
    },
    async clearNotifications() {
      await this.$store
        .dispatch('notification/clearNotifications')
        .then(async () => {
          this.get();
          this.$gtm.trackEvent({
            category: 'notification',
            event: 'click',
            action: 'notification-click',
            label: 'notification-clear-all',
          });
        });
    },
    notificationView(route) {
      this.$router.push(route);
    },
  },
  computed: {
    currentLanguage() {
      return this.languages.includes(this.$i18n.locale)
        ? this.$i18n.locale
        : this.languages[0];
    },
    countNotifications() {
      return this.list.filter(item => item.status == 'unread');
    },
  },
  mounted: function() {
    this.get();
    this.onChangeLoggedIn(this.$store.state.auth.isLogged);
  },
  beforeDestroy: function() {
    if (this.notificationInterval) {
      clearInterval(this.notificationInterval);
    }
  },
  watch: {
    $route: 'get',
    '$store.state.loggedIn': 'onChangeLoggedIn',
  },
};
</script>
