<template>
  <div>
    <img :src="imgLink" class="logo" v-if="true" />
  </div>
</template>

<script>
import { UserType } from '../../helpers/user_utils';
import { KEYS as COLOR_SCHEMES } from '../../data/templateColorSchemes';

export default {
  props: {
    change: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isAdmin() {
      return this.$store.state.auth.user.type == UserType.Admin;
    },
    imgLink() {
      let imgLink = '/img/LMS-FlukeLogo-black.svg';
      if (
        this.change &&
        this.$store.getters['system/colorScheme'] == COLOR_SCHEMES.BLUE
      )
        imgLink = '/img/LMS-FlukeLogo.svg';
      return imgLink;
    },
  },
  methods: {
    goTo(routeName) {
      this.$router.push({
        name: routeName,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  img {
    width: 250px;
  }
}
img.logo {
  width: 250px;
}
</style>
