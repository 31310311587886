import apiUtils from "./api_utils";

export const UserType = {
  Admin: "admin",
  Master: "master",
  TSM: "tsm",
  FACT: "fact",
  Student: "student"
};

export const UserListStatus = {
  Pending: "pending",
  Enabled: "enabled"
};

const userUtils = {
  avatarURL(name) {
    return apiUtils.getURL() + `file/user/${name}`;
  },
  getType(user) {
    let userType = UserType.Student;
    if (user.type) {
      userType = user.type;
    } else {
      if (user.isAdmin) userType = UserType.Admin;
      else if (user.isMaster) userType = UserType.Master;
      else if (typeof user.TSM !== "undefined" && user.TSM.countries.length)
        userType = UserType.TSM;
    }
    return userType;
  }
};

export default userUtils;
