import axios from 'axios'
import envs from '../../env'

const fileUploader = {
  async pdf(file, token) {
    return this.generic('module_pdf', file, token)
  },
  async material(file, token) {
    return this.generic('module_material', file, token)
  },
  async avatar(file, token) {
    return this.generic('user_avatar', file, token)
  },
  async courseImage(file, token) {
    return this.generic('course_image', file, token)
  },
  async question(file, token) {
    return this.generic('question_image', file, token)
  },
  async generic(type, file, token) {
    const formData = new FormData()
    formData.append('files', file)
    formData.append('type', type)
    const { data } = await axios.post(
      envs.API_URL + 'file',
      formData,
      {
        headers: {
          'Authorization': token,
          'Content-Type': 'multipart/form-data'
        }
      }
    )
    return data.body
  },
}

export default fileUploader
