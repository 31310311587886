import axios from "axios";
import envs from "../../env";

/**
 * Utilidades para API
 */
const apiUtils = {
  /**
   * Basicamente a função nativa URLSearchParams
   */
  jsonToQuery(json) {
    return Object.keys(json)
      .map(key => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`;
      })
      .join("&");
  },

  arrayToQuery(arrayItems, arrayName = "data") {
    return arrayItems
      .map(item => {
        return `${arrayName}[]=${encodeURIComponent(item)}`;
      })
      .join("&");
  },

  /**
   * Retorna url da api
   * @returns {string}
   */
  getURL() {
    return envs.API_URL;
  },

  /**
   * Monta url
   * @param {string} path
   * @returns {string}
   */
  buildURL(path) {
    return this.getURL() + path;
  },

  /**
   * Post
   * @param {string} path
   * @param {object} [data={}]
   * @param {string} [token=null]
   * @param {object} [axiosConfig={}]
   * @returns {Promise}
   */
  post(path, data = {}, token = null, axiosConfig = {}) {
    return this.request("post", path, data, token, axiosConfig);
  },

  /**
   * Put
   * @param {string} path
   * @param {object} [data={}]
   * @param {string} [token=null]
   * @param {object} [axiosConfig={}]
   * @returns {Promise}
   */
  put(path, data = {}, token = null, axiosConfig = {}) {
    return this.request("put", path, data, token, axiosConfig);
  },

  /**
   * Get
   * @param {string} path
   * @param {object} [params={}]
   * @param {string} [token=null]
   * @param {object} [axiosConfig={}]
   * @returns {Promise}
   */
  get(path, params = {}, token = null, axiosConfig = {}) {
    return this.request("get", path, params, token, axiosConfig);
  },

  /**
   * Post
   * @param {string} path
   * @param {object} [data={}]
   * @param {string} [token=null]
   * @param {object} [axiosConfig={}]
   * @returns {Promise}
   */
  delete(path, data = {}, token = null, axiosConfig = {}) {
    return this.request("delete", path, data, token, axiosConfig);
  },

  /**
   * Retorna headers
   * @param {object} [data={}]
   * @param {string} [token=null]
   * @returns {object}
   */
  buildHeaders(data = {}, token = null) {
    let headers = {};
    if (data.headers) {
      headers = Object.assign({}, data.headers);
    }
    if (token) {
      headers["Authorization"] = token;
    }
    return headers;
  },

  /**
   * Faz request
   * @param {string} method
   * @param {string} path
   * @param {object} [data={}]
   * @param {object} [token=null]
   * @param {object} [axiosConfig={}]
   * @returns {Promise}
   */
  async request(method, path, data = {}, token = null, axiosConfig = {}) {
    const headers = this.buildHeaders(data, token);
    const instance = axios.create({
      baseURL: this.getURL(),
      headers,
      ...axiosConfig
    });
    let config = {
      url: path,
      method
    };
    if (method === "get") {
      config.params = data || {};
    } else {
      config.data = data || {};
    }
    return instance
      .request(config)
      .then(response => response.data)
      .catch(error => {
        if (error.response) {
          return error.response;
        }
        throw error;
      });
  }
};

export default apiUtils;
