import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"show-line"},[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"black"}},[_vm._v(" "+_vm._s(_vm.notification.status == 'read' ? 'mdi-email-open' : 'mdi-email')+" ")])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-uppercase",domProps:{"textContent":_vm._s(_vm.$i18n.t(_vm.notification.title))}}),_c(VListItemSubtitle,{domProps:{"textContent":_vm._s(_vm.notification.describe)}}),_c(VListItemSubtitle,{domProps:{"textContent":_vm._s(_vm.notification.createdAt)}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }