import Breadcrumbs from '../components/shared/Breadcrumbs.vue';

import Card from '../components/shared/NCard/Card.vue';
import CardItem from '../components/shared/NCard/Item.vue';
import CardItemBtn from '../components/shared/NCard/ItemActionBtn.vue';
import CardTitle from '../components/shared/NCard/Title.vue';
import CardTableFilters from '../components/shared/NCard/TableFilters.vue';

import DataTableBtn from '../components/shared/DataTableBtn.vue';
import DefaultLoader from '../components/shared/LoaderData.vue';
import BtnHolder from '../components/shared/FormBtnHolder.vue';

import PageHeaderActionBtn from '../components/shared/PageHeaderActionBtn.vue';
import PageHeaderActionSpacer from '../components/shared/PageHeaderActionSpacer.vue';
import PageHeaderTabs from '../components/shared/PageHeaderTabs.vue';

export default {
  install(Vue) {
    Vue.component('lms-breadcrumbs', Breadcrumbs);
    Vue.component('lms-card', Card);
    Vue.component('lms-card-item', CardItem);
    Vue.component('lms-card-item-action-btn', CardItemBtn);
    Vue.component('lms-card-title', CardTitle);
    Vue.component('lms-card-table-filters', CardTableFilters);
    Vue.component('lms-data-table-btn', DataTableBtn);
    Vue.component('lms-default-loader', DefaultLoader);
    Vue.component('lms-btn-holder', BtnHolder);
    Vue.component('lms-page-header-action-btn', PageHeaderActionBtn);
    Vue.component('lms-page-header-action-spacer', PageHeaderActionSpacer);
    Vue.component('lms-page-header-tabs', PageHeaderTabs);
    Vue.component('lms-page-header-tabs', PageHeaderTabs);
  },
};
