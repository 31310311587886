<template>
  <div class="user-avatar ml-4" @click="goTo('profile-view')">
    <v-avatar class="header-buttons">
      <img v-if="avatar" :src="avatar" />
      <v-icon v-if="!avatar" dark> mdi-account-circle-outline </v-icon>
    </v-avatar>
  </div>
</template>

<script>
export default {
  computed: {
    avatar() {
      const { user } = this.$store.state;
      const avatar = user.avatar ? userUtils.avatarURL(user.avatar) : null;
      return avatar;
    },
  },
  methods: {
    goTo(routeName) {
      this.$router.push({
        name: routeName,
      });

      this.$gtm.trackEvent({
        category: 'header-menu',
        event: 'click',
        action: 'header-menu-click',
        label: 'menu-profile',
      });
    },
  },
};
</script>
