import courseUtils from '../helpers/course_utils';
import store from '../store';
import { isLinear } from './courses';

const COURSE_TYPE = {
  LINEAR: 'linear',
  MODULAR: 'modular',
};
/**
 *
 * @param {courseID,lessonID,moduleID?} data
 * @param courseType
 * @returns
 */
export const isLessonDone = (data, courseType = COURSE_TYPE.LINEAR) => {
  const user = store.getters['auth/user'];
  let _progress, courseProgress, lesson, mdl;
  if (courseType == COURSE_TYPE.LINEAR) {
    _progress = 0;
    try {
      courseProgress = user.progress.find(
        item => item.courseID == data.courseID
      );
      lesson = courseProgress.modules.find(
        item => item.moduleID == data.lessonID
      );
      _progress = lesson.progress;
    } catch (e) { }
    return _progress >= 99.5;
  } else {
    let _progress = 0;
    try {
      courseProgress = user.progress.find(
        item => item.courseID == data.courseID
      );
      mdl = courseProgress.modules.find(item => item.moduleID == data.moduleID);
      lesson = mdl.lessons.find(item => item.lessonID == data.lessonID);
      _progress = lesson.progress;
    } catch (e) { }
    return _progress >= 99.5;
  }
};

export const getCourseProgress = (
  course,
  courseType = COURSE_TYPE.LINEAR
) => {
  let _progress = 0;
  const user = store.getters['auth/user'];

  try {
    const courseProgress = user.progress.find(
      item => item.courseID == course._id
    );
    let courseModules;
    if (courseType == COURSE_TYPE.LINEAR) {
      courseModules = course.modules;
    } else {
      courseModules = [];
      const modulesCount = course.modules.length;
      for (var i = 0; i < modulesCount; i++) {
        courseModules.push(course.modules[i].lessons);
      }
      courseModules = courseModules.flat(1);
    }

    const moduleExamNotApproved = courseUtils.moduleExamNotApproved(user, courseModules, course._id)

    const cmTotal = courseModules.length * 100;
    let cmDone;

    if (courseType == COURSE_TYPE.LINEAR) {
      for (let i = 0; i < courseProgress.modules.length; i++) {
        let hasModules = false
        if (moduleExamNotApproved != undefined) {
          hasModules = moduleExamNotApproved.includes(courseProgress.modules[i].moduleID)
        }

        if (hasModules) {
          courseProgress.modules.splice(i, 1)
        }
      }


      cmDone = courseProgress.modules.reduce(
        (total, item) => total + item.progress,
        0
      );
    } else {
      let flatProgress = [];
      const modulesProgress = [];
      courseProgress.modules.find(mdl => {
        if (mdl.lessons.length !== 0 && mdl.moduleID) modulesProgress.push(mdl);
      });


      for (var i = 0; i < course.modules.length; i++) {
        if (typeof modulesProgress[i] != 'undefined') {
          flatProgress.push(modulesProgress[i].lessons);
        }
      }
      flatProgress = flatProgress.flat(1);
      for (let i = 0; i < flatProgress.length; i++) {
        let hasModules = false;

        if (moduleExamNotApproved != undefined) {
          hasModules = moduleExamNotApproved.includes(flatProgress[i].lessonID)
        }

        if (hasModules) {
          flatProgress.splice(i, 1)
        }
      }

      cmDone = flatProgress
        .flat(1)
        .reduce((total, item) => total + item.progress, 0);
    }

    _progress = (100 * cmDone) / cmTotal;
    _progress = _progress >= 100 ? 100 : _progress;
    return _progress.toFixed(1);
  } catch (e) {
    return (_progress = 0);
  }
};

export const getCourseProgress_fromFullCourse = course => {
  let _progress = 0;
  const user = store.getters['auth/user'];

  // course type
  const courseType =
    typeof course.structure != 'undefined' &&
      course.structure == COURSE_TYPE.MODULAR
      ? COURSE_TYPE.MODULAR
      : COURSE_TYPE.LINEAR;
  const courseID = course._id;

  try {
    const courseProgress = user.progress.find(
      item => item.courseID == courseID
    );

    if (!courseProgress) throw new Error('no progress');

    let courseModules;
    if (courseType == COURSE_TYPE.LINEAR) {
      courseModules = course.modules;
    } else {
      courseModules = [];
      const modulesCount = course.modules.length;
      for (var i = 0; i < modulesCount; i++) {
        courseModules.push(course.modules[i].lessons);
      }
      courseModules = courseModules.flat(1);
    }

    const cmTotal = courseModules.length * 100;

    let cmDone;
    if (courseType == COURSE_TYPE.LINEAR) {
      cmDone = courseProgress.modules.reduce(
        (total, item) => total + item.progress,
        0
      );
    } else {
      const flatProgress = [];
      const modulesProgress = [];
      courseProgress.modules.find(mdl => {
        if (mdl.lessons.length !== 0 && mdl.moduleID) modulesProgress.push(mdl);
      });
      for (var i = 0; i < course.modules.length; i++) {
        if (typeof modulesProgress[i] != 'undefined') {
          flatProgress.push(modulesProgress[i].lessons);
        }
      }
      cmDone = flatProgress
        .flat(1)
        .reduce((total, item) => total + item.progress, 0);
    }

    _progress = (100 * cmDone) / cmTotal;
    _progress = _progress >= 100 ? 100 : _progress;
    return _progress.toFixed(1);
  } catch (e) {
    return (_progress = 0);
  }
};
