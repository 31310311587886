export default [
  {
    path: '/settings',
    name: 'settings',
    component: function () {
      return import(
        /* webpackChunkName: "settings" */ '../views/setting/Index.vue'
      );
    },
  },
  {
    path: '/settings/time_between_exams',
    name: 'settings-time-between-exams',
    component: function () {
      return import(
        /* webpackChunkName: "settings-time-between-exams" */ '../views/setting/configs/TimeBetweenExams.vue'
      );
    },
  },
  {
    path: '/settings/max_exam_tries',
    name: 'settings-max-exam-tries',
    component: function () {
      return import(
        /* webpackChunkName: "settings-max-exam-tries" */ '../views/setting/configs/MaxExamTries.vue'
      );
    },
  },

  // country
  {
    path: '/settings/countries',
    name: 'settings-country-list',
    component: function () {
      return import(
        /* webpackChunkName: "settings-country-list" */ '../views/setting/data/country/List.vue'
      );
    },
  },
  {
    path: '/settings/country/edit/:id',
    name: 'settings-country-edit',
    component: function () {
      return import(
        /* webpackChunkName: "settings-country-edit" */ '../views/setting/data/country/Edit.vue'
      );
    },
  },

  // distributor
  {
    path: '/settings/country/:countryid/distributor/edit/:id',
    name: 'settings-distributor-edit',
    component: function () {
      return import(
        /* webpackChunkName: "settings-distributor-edit" */ '../views/setting/data/country/DistributorCreateEdit.vue'
      );
    },
  },
  {
    path: '/settings/country/:countryid/distributor/create',
    name: 'settings-distributor-create',
    component: function () {
      return import(
        /* webpackChunkName: "settings-distributor-create" */ '../views/setting/data/country/DistributorCreateEdit.vue'
      );
    },
  },

  {
    path: '/settings/training_types',
    name: 'settings-training-type-list',
    component: function () {
      return import(
        /* webpackChunkName: "settings-training-type-list" */ '../views/setting/data/TrainingTypeList.vue'
      );
    },
  },
  {
    path: '/settings/training_type/create',
    name: 'settings-training-type-create',
    component: function () {
      return import(
        /* webpackChunkName: "settings-training-type-create" */ '../views/setting/data/TrainingTypeCreateEdit.vue'
      );
    },
  },
  {
    path: '/settings/training_type/edit/:id',
    name: 'settings-training-type-edit',
    component: function () {
      return import(
        /* webpackChunkName: "settings-training-type-edit" */ '../views/setting/data/TrainingTypeCreateEdit.vue'
      );
    },
  },

  {
    path: '/settings/user_categories',
    name: 'settings-user-category-list',
    component: function () {
      return import(
        /* webpackChunkName: "settings-user-category-list" */ '../views/setting/data/UserCategoryList.vue'
      );
    },
  },
  {
    path: '/settings/user_category/create',
    name: 'settings-user-category-create',
    component: function () {
      return import(
        /* webpackChunkName: "settings-user-category-create" */ '../views/setting/data/UserCategoryCreateEdit.vue'
      );
    },
  },
  {
    path: '/settings/user_category/edit/:id',
    name: 'settings-user-category-edit',
    component: function () {
      return import(
        /* webpackChunkName: "settings-user-category-edit" */ '../views/setting/data/UserCategoryCreateEdit.vue'
      );
    },
  },
  {
    path: '/settings/users_check',
    name: 'settings-users-check',
    component: function () {
      return import(
        /* webpackChunkName: "settings-users-check" */ '../views/setting/data/UsersCheck.vue'
      );
    },
  },
].map(item => {
  if (!item['meta']) {
    item['meta'] = { menu: 'settings' };
  }
  return item;
});
