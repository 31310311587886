import apiUtils from '../helpers/api_utils';
import userTypes from '../data/userTypes';
import langs from '../data/languages';
import templateColorSchemes, {
  KEYS as COLOR_SCHEMES,
} from '../data/templateColorSchemes';
import { i18n } from '../main';

const INIT_LIST_CONTROL_DATA = {
  page: 1,
  total: 1,
  order: {
    saved: false,
    timeout: null,
    willBeSaved: false,
  },
};

const store = {
  namespaced: true,
  state: () => ({
    langs,
    selectedLang: null,
    userTypes,
    i18nColumns: {
      course: ['title', 'description'],
    },
    currentRoute: null,
    mobileMenu: {
      isOpen: false,
    },

    // system template control
    colorScheme: COLOR_SCHEMES.YELLOW,

    // system permission control
    superAdmin: false,

    // system load control
    isLoadingData: false,
    isLoadingCtl: null,
    isLocked: false,

    // system list control
    listControl: { ...INIT_LIST_CONTROL_DATA },

    screenSizeCtl: {
      isOpen: false,
      size: {
        current: 0,
        big: 0,
        small: 0,
      },
      gap: 0,
    },
  }),
  getters: {
    isLoading: ({ isLoadingData }) => isLoadingData,
    isEditPage: ({ currentRoute }) => currentRoute.name.includes('edit'),
    langs: ({ langs }) => langs,
    selectedInputsLang: ({ selectedLang }) => selectedLang,
    colorScheme: ({ colorScheme }) => colorScheme,
    currentRoute: ({ currentRoute }) => currentRoute,
  },
  mutations: {
    setTemplateColorScheme(state, value) {
      if (templateColorSchemes.includes(value)) {
        state.colorScheme = value;
      }
    },
    loading(state, value = true) {
      state.isLoadingData = value;
    },
    setRoute(state, route) {
      state.currentRoute = route;
    },
    selectLang(state, value) {
      i18n.locale = value;
      // state.selectedLang = value;
    },
    selectInputsLang(state, value) {
      state.selectedLang = value;
    },
    pageEditing(state, value = true) {
      state.editing = value;
    },
    pageLoaded(state) {
      state.isLoadingData = false;
    },
    resetListControls(state) {
      state.listControl = { ...INIT_LIST_CONTROL_DATA };
    },
  },
  actions: {
    async order({ rootGetters }) {
      const token = rootGetters['auth/userToken'];
      const data = await apiUtils.get('system/server_time', {}, token);
      return data.body;
    },
    async serverTime({ rootGetters }) {
      const token = rootGetters['auth/userToken'];
      const data = await apiUtils.get('system/server_time', {}, token);
      return data.body;
    },
  },
};

export default store;
