import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import pt from 'vuetify/lib/locale/pt';
import en from 'vuetify/lib/locale/en';
import es from 'vuetify/lib/locale/es';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { pt, en, es },
    current: 'pt',
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#FDC031',
        secondary: '#E55991',
        accent: colors.shades.black,
        error: colors.red.accent3,
        warning: colors.red.accent3,
        info: colors.red.accent3,
        success: colors.red.accent3,
        anchor: colors.grey.darken4,
      },
    },
  },
});
