import store from '../store';
import apiUtils from '../helpers/api_utils';

const ROUTE = 'report';

// Services Courses: BEGIN
export const courseList = async (filters = null) => {
  const TOKEN = store.getters['auth/userToken'];
  return (await apiUtils.get(`${ROUTE}/course-list`, filters, TOKEN)).body;
};

export const courseView = async id => {
  const TOKEN = store.getters['auth/userToken'];
  return (await apiUtils.get(`${ROUTE}/course-view/${id}`, {}, TOKEN)).body;
};

export const downloadCourse = async (filters = null) => {
  const TOKEN = store.getters['auth/userToken'];

  return await apiUtils.get(`${ROUTE}/course-download`, filters, TOKEN, {
    responseType: 'blob',
  });
};
// END

// Services Students: BEGIN
export const studentList = async (filters = null) => {
  const TOKEN = store.getters['auth/userToken'];
  return (await apiUtils.get(`${ROUTE}/student-list`, filters, TOKEN)).body;
};

export const studentView = async id => {
  const TOKEN = store.getters['auth/userToken'];
  return (await apiUtils.get(`${ROUTE}/student-view/${id}`, {}, TOKEN)).body;
};
// END

export const getTabsCounters = async () => {
  const TOKEN = store.getters['auth/userToken'];
  return (await apiUtils.get(`${ROUTE}/get-tabs-counters`, {}, TOKEN)).body;
};
