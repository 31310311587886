import stringHelper from '../helpers/strings';
import SCREENS from '../data/ViewerTestScreens';

const store = {
  namespaced: true,
  state: () => ({
    langs: [],
    langCtl: 0,
    list: [],
    question: {
      id: '',
      type: '0',
      title: {},
      image: {},
      result: null,
      answers: [],
    },
    min: 0,
    answer: '',
    isAdding: false,
    isAnswerAdding: false,
    alerts: {
      questions: false,
      answers: false,
    },
    view: {
      questions: [],
      attempts: [],
      userAnswers: {},
      showingAnswers: false,
      validForm: false,
      run: {
        corrects: 0,
        progress: 0,
      },
      screen: SCREENS.LOADING,
    },
  }),
  mutations: {
    answerAdd(state, payload) {
      state.question.answers.push(payload);
      state.isAnswerAdding = false;
    },
    questionAdd(state) {
      state.question.id = stringHelper.random(24);
      state.list.push(state.question);
      state.question = {
        id: '',
        type: '0',
        title: {},
        image: {},
        result: null,
        answers: [],
      };
      state.isAdding = false;
    },
    questionEdit(state) {
      const index = state.list.map(item => item.id).indexOf(state.question.id);
      state.list[index] = state.question;
      state.question = {
        id: '',
        type: '0',
        title: {},
        image: {},
        result: null,
        answers: [],
      };
      state.isAdding = false;
    },
    questionRemove(state, questionID) {
      const index = state.list.map(item => item.id).indexOf(questionID);
      state.list.splice(index, 1);
    },
    questionCancel(state) {
      state.question = {
        id: '',
        type: '0',
        title: {},
        image: {},
        result: null,
        answers: [],
      };
      state.isAdding = false;
    },
    setAdding(state, payload) {
      state.isAdding = payload;
    },
    setMin(state, payload) {
      state.min = payload;
    },
    answerEdit(state, answerID) {
      state.answer = answerID;
      state.isAnswerAdding = true;
    },
    answerRemove(state, answerID) {
      const index = state.question.answers
        .map(item => item.id)
        .indexOf(answerID);
      state.question.answers.splice(index, 1);
    },
    setAnswerAdding(state, payload) {
      if (payload) {
        state.answer = '';
      }
      state.isAnswerAdding = payload;
    },
    setLangs(state, payload) {
      state.langs = payload;
    },
    setQuestion(state, payload) {
      state.question = payload;
    },
    setQuestions(state, payload) {
      state.list = payload;
    },
    switchLang(state, payload) {
      state.langCtl = payload;
    },
    alertQuestions(state) {
      state.alerts.questions = true;
    },
    alertAnswers(state) {
      state.alerts.answers = true;
    },
    clearAlerts(state) {
      state.alerts.questions = false;
      state.alerts.answers = false;
    },
    cleanData(state) {
      state.langs = [];
      state.langCtl = 0;
      state.list = [];
      state.question = {
        id: '',
        type: '0',
        title: {},
        image: {},
        result: null,
        answers: [],
      };
      state.min = 0;
      state.answer = '';
      state.isAdding = false;
      state.isAnswerAdding = false;
      state.alerts = {
        questions: false,
        answers: false,
      };
      state.view = {
        questions: [],
        attempts: [],
        userAnswers: {},
        showingAnswers: false,
        validForm: false,
        run: {
          corrects: 0,
          progress: 0,
        },
        screen: SCREENS.LOADING,
      };
    },
  },
  actions: {},
};

export default store;
