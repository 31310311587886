<template>
  <div class="lms-card" :class="classes">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    accent: {
      type: Boolean,
      default: false,
    },
    sideMargin: {
      type: Boolean,
      default: false,
    },
    bottomMargin: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        'lms-card': true,
        'lms-accent': this.accent,
        'lms-side-margin': this.sideMargin,
        'lms-bottom-margin': this.bottomMargin,
        'lms-padding': this.padding,
      };
    },
  },
};
</script>
