const store = {
  namespaced: true,
  state: () => ({
    url: null,
    active: false
  }),
  getters: {
    getURL({ url }) {
      return url
    },
    isActive({ active }) {
      return active
    },
  },
  mutations: {
    setURL(state, url) {
      state.url = url
    },
    setActive(state, active) {
      state.active = active
    },
  },
  actions: {
    async openModal({ commit }, url) {
      commit('setURL', url)
      commit('setActive', true)
    },
    async closeModal({ commit }) {
      commit('setURL', null)
      commit('setActive', false)
    }
  }
}

export default store
