import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueHead from 'vue-head';
import VueMask from 'v-mask';

import VueYoutube from 'vue-youtube';
import VueI18n from 'vue-i18n';
import VueDragscroll from 'vue-dragscroll';
import VueGtag from 'vue-gtag';

import AdminGlobals from './plugins/globals';
import AxiosInterceptors from './plugins/axios_interceptors';
import vuetify from './plugins/vuetify';
import TextTransform from './plugins/textTransform';
import DateFormatter from './plugins/date_formatter';
import AllowedFor from './plugins/allowed_for';
import TemplateColorScheme from './plugins/template_scheme';
import EventHub from './plugins/event_hub';

import GlobalMixin from './mixins/global';

import Default from './layouts/Default.vue';
import Login from './layouts/Login.vue';
import Clear from './layouts/Clear.vue';

import Container from './components/shared/Container.vue';
import LangSelector from './components/shared/LangSelector.vue';
import PageHeader from './components/shared/PageHeader.vue';

import EN_LANG from './i18n/en';
import PT_LANG from './i18n/pt';
import ES_LANG from './i18n/es';
import SharedComponents from './plugins/shared_components';

Vue.config.productionTip = false;

Vue.use(AdminGlobals);
Vue.use(AxiosInterceptors);
Vue.use(TextTransform);
Vue.use(DateFormatter);
Vue.use(EventHub);
Vue.use(VueYoutube);
Vue.use(VueDragscroll);
Vue.use(VueHead);
Vue.use(AllowedFor);
Vue.use(TemplateColorScheme);
Vue.use(VueMask);

Vue.component('default-layout', Default);
Vue.component('login-layout', Login);
Vue.component('clear-layout', Clear);

Vue.component('f-container', Container);
Vue.component('f-lang-selector', LangSelector);
Vue.component('f-page-header', PageHeader);

Vue.use(SharedComponents);

Vue.use(VueI18n);
const messages = {
  en: { ...EN_LANG },
  pt: { ...PT_LANG },
  es: { ...ES_LANG },
};
export const i18n = new VueI18n({
  locale: 'en',
  messages,
});

Vue.use(VueGtag, {
  config: {
    id: 'G-JQDZEPYCT4',
    params: {
      send_page_view: false
    }
  }
}, router)

Vue.mixin(GlobalMixin);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: function (h) {
    return h(App);
  },
}).$mount('#app');
