const SCREENS = {
  LOADING: 'loading',
  EXAM: 'exam',
  RESULT: 'result',
  TOO_MANY_TRIES: 'tries',
  TOO_SOON: 'soon',
  REQUIRE_COMPLETED_MODULES_BEFORE_EXAM: 'beforeExam',
};

export default SCREENS;
