<template>
  <div class="global-dialog">
    <v-dialog v-model="showContent" width="500">
      <v-card>
        <v-card-title>
          {{ $store.state.dialog.title }}
        </v-card-title>

        <v-card-text>
          {{ $store.state.dialog.content }}
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            elevation="0"
            v-if="$store.state.dialog.type == 'prompt'"
            text
            @click="cancel"
          >
            {{
              $store.state.dialog.cancel === false
                ? $t('alerts.general.buttons.cancel')
                : $store.state.dialog.cancel
            }}
          </v-btn>
          <v-btn elevation="0" @click="confirm" color="primary">
            {{
              $store.state.dialog.accept === false
                ? $t('alerts.general.buttons.accept')
                : $store.state.dialog.accept
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: _ => ({
    showContent: false,
  }),
  created() {
    this.$store.subscribeAction((action, state) => {
      if (action.type === 'dialog/show') {
        this.showContent = true;
      } else if (action.type === 'dialog/resolve') {
        this.showContent = false;
      }
    });
  },
  methods: {
    confirm() {
      this.$store.dispatch('dialog/resolve', true);
    },
    cancel() {
      this.$store.dispatch('dialog/resolve', false);
    },
  },
};
</script>
