import store from '../store';
import apiUtils from '../helpers/api_utils';
import { isLinear } from './courses';

const ROUTE = 'auth';

export const getData = async (items = [], userID = null) => {
  const TOKEN = store.getters['auth/userToken'];

  let query = '';
  if (items) query = '?' + apiUtils.arrayToQuery(items);
  if (userID) query += '&userID=' + userID;

  return (await apiUtils.get(`${ROUTE}/get_data${query}`, {}, TOKEN)).body;
};

export const getModuleProgress = (courseID, moduleID) => {
  try {
    const userProgress = store.state.auth.user.progress;
    const courseProgress = userProgress.find(item => item.courseID == courseID);
    const moduleProgress = courseProgress.modules.find(
      mdl => mdl.moduleID == moduleID
    );
    return moduleProgress.progress;
  } catch (e) {
    return 0;
  }
};

export const getUserAttempts = () => {
  const authUser = store.getters['auth/user'];
  const userAttempts = authUser.examAttempts;
  let userAttemptsCount = 0;

  try {
    const _isLinear = isLinear();
    if (_isLinear || true) {
      const courseID = store.getters['course/current']._id;
      const lessonID = store.getters['lesson/current']._id;

      const course = userAttempts.find(cour => cour.courseID == courseID);
      const lesson = course.modules.find(les => les.moduleID == lessonID);

      userAttemptsCount = lesson.list.length;
    }

    return userAttemptsCount;
  } catch (e) {
    return 0;
  }
};
