import moment from 'moment'

const notificationUtils = {
  formatData: (notification, locale) => {
    const list = []
    if (notification.length === 0) {
      list.push({
        title: "notifications.default",
        describe: "Nothing to do!",
        icon: "mdi-thumb-up",
        status: 'read',
        type: 'clear'
      })
    } else {
      notification.map(function (notification) {
        list.push({
          _id: notification._id,
          title: "notifications." + notification.type + "." + notification.action,
          describe: notificationDescribe(notification, locale),
          status: notification.status,
          type: notification.type,
          createdAt: dateFormat(notification.createdAt),
          route: notification.route
        })
      })
    }
    return list
  },
}

function notificationDescribe(notification, locale) {
  const currentLanguage = notification.langs.includes(locale)
    ? locale
    : notification.langs[0]

  return notification.describe[currentLanguage] || notification.describe
}

function dateFormat(date) {
  return moment(String(date)).format("DD/MM/YYYY")
}

export default notificationUtils
