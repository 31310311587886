import store from '../store';
import apiUtils from '../helpers/api_utils';

const ROUTE = 'country/distributors/';

export const list = async countryID => {
  const TOKEN = store.getters['auth/userToken'];

  return (await apiUtils.get(`${ROUTE}${countryID}/list`, {}, TOKEN)).body;
};

export const get = async (countryID, id) => {
  const TOKEN = store.getters['auth/userToken'];

  return (await apiUtils.get(`${ROUTE}${countryID}/get/${id}`, {}, TOKEN)).body;
};

export const save = async (countryID, distributor) => {
  const TOKEN = store.getters['auth/userToken'];

  return (
    await apiUtils.post(
      `${ROUTE}save`,
      {
        countryID,
        distributor,
      },
      TOKEN
    )
  ).body;
};

export const remove = async (countryID, distributorID) => {
  const TOKEN = store.getters['auth/userToken'];

  ///distributors/:countryid/:distributorid

  return (
    await apiUtils.delete(`${ROUTE}${countryID}/${distributorID}`, {}, TOKEN)
  ).body;
};
