import store from '../store';
import apiUtils from '../helpers/api_utils';
import { i18n } from '../main';
import * as courseModules from './courseModules';
import * as courseLessons from './courseLessons';
import fileUploader from '../helpers/file_uploader';

const ROUTE = 'course';

export const list = async (filters = null) => {
  const TOKEN = store.getters['auth/userToken'];

  filters.page = 'false'; // @TODO: Remover essa linha para voltar paginacao

  let query = '';
  if (filters) query = '?' + apiUtils.jsonToQuery(filters);

  return (await apiUtils.get(`${ROUTE}/list${query}`, {}, TOKEN)).body;
};

export const get = async id => {
  const TOKEN = store.getters['auth/userToken'];
  return (await apiUtils.get(`${ROUTE}/get/${id}`, {}, TOKEN)).body;
};

// standardizing masters and teams, send only IDs
const standardize = course => {
  let cleanCourse = course;

  if (
    typeof course.masters != 'undefined' &&
    course.masters.length &&
    typeof course.masters[0] != 'string'
  ) {
    cleanCourse.masters = course.masters.map(master => master._id);
  }
  if (
    typeof course.teams !== 'undefined' &&
    course.teams.length &&
    typeof course.teams[0] != 'string'
  ) {
    cleanCourse.teams = course.teams.map(team => team._id);
  }
  return cleanCourse;
};

export const update = async course => {
  const cleanCourse = standardize(course);

  const TOKEN = store.getters['auth/userToken'];

  if (cleanCourse.image && typeof cleanCourse.image != 'string') {
    const filename = await fileUploader.courseImage(cleanCourse.image, TOKEN);
    cleanCourse.image = filename;
  }

  return (await apiUtils.post(`${ROUTE}/update`, cleanCourse, TOKEN)).body;
};

export const create = async course => {
  const cleanCourse = standardize(course);

  const TOKEN = store.getters['auth/userToken'];

  if (cleanCourse.image) {
    const filename = await fileUploader.courseImage(cleanCourse.image, TOKEN);
    cleanCourse.image = filename;
  }

  return (await apiUtils.post(`${ROUTE}/create`, cleanCourse, TOKEN)).body;
};

export const changeStatus = async course => {
  const cleanCourse = standardize(course);

  const TOKEN = store.getters['auth/userToken'];

  return (await apiUtils.post(`${ROUTE}/update`, cleanCourse, TOKEN)).body;
};

/**
 * Verify if user is using one of languages available on the course
 * if not choose one of languages available in the course
 *
 * @param {*} course
 * @returns
 */
export const getCurrentLanguage = course => {
  const langArr = course && course.langs ? course.langs : [];
  return langArr.includes(i18n.locale) ? i18n.locale : langArr[0];
};

export const getImageURL = file => {
  return `${apiUtils.getURL()}file/course/image/${file}`;
};

export const orderSave = async courses => {
  const TOKEN = store.getters['auth/userToken'];
  return (
    await apiUtils.post(
      'course/ordersave',
      courses.map((item, index) => {
        item.position = index;
        return item;
      }),
      TOKEN
    )
  ).body;
};

export const remove = async courseID => {
  const TOKEN = store.getters['auth/userToken'];
  return (await apiUtils.delete(`course/${courseID}`, {}, TOKEN)).body;
};

export const headerTabsCounters = async () => {
  const TOKEN = store.getters['auth/userToken'];
  return (await apiUtils.get(`${ROUTE}/header-tabs-counters`, {}, TOKEN)).body;
};

export const isLinear = () => {
  const course = store.getters['course/current'];
  let _isLinear = false;
  if (typeof course.structure == 'undefined' || course.structure == 'linear') {
    _isLinear = true;
  }

  return _isLinear;
};

export const modules = courseModules;
export const lessons = courseLessons;
