import apiUtils from "../helpers/api_utils"

const store = {
  namespaced: true,
  state: () => ({
    list: []
  }),
  mutations: {
    setList(state, list) {
      state.list = list
    }
  },
  actions: {
    async list({ rootGetters }) {
      const token = rootGetters['auth/userToken']
      const data = await apiUtils.get('notification/list', {}, token)
      return data.body || []
    },
    async markAsRead({ rootGetters }, id) {
      const token = rootGetters['auth/userToken']
      const data = await apiUtils.get(`notification/mark-as-read/${id}`, {}, token)
      return data.body || []
    },
    async clearNotifications({ rootGetters }) {
      const token = rootGetters['auth/userToken']
      const data = await apiUtils.post('notification/clear-notifications', {}, token)
      return data.body || []
    }
  }
}

export default store
