<template>
  <div>
    <v-app>
      <v-main>
        <div class="aligner">
          <div class="small-content">
            <slot />
          </div>
        </div>
      </v-main>
      <f-snackbar />
    </v-app>
  </div>
</template>

<script>
import FSnackbar from '../components/shared/Snackbar.vue';

export default {
  components: {
    FSnackbar,
  },
};
</script>

<style lang="scss">
@import '../assets/scss/global';
</style>

<style lang="scss" scoped>
.aligner {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
}
.small-content {
  margin: 0 auto;
  width: 100%;
  max-width: 450px;
}
</style>
