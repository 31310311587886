import store from '../store';
import apiUtils from '../helpers/api_utils';

const ROUTE = 'rating';

export const getLessonRating = async lessonID => {
  const TOKEN = store.getters['auth/userToken'];

  return (await apiUtils.get(`${ROUTE}/get/${lessonID}`, {}, TOKEN)).body;
};
