import store from '../store';
import apiUtils from '../helpers/api_utils';
import { i18n } from '../main';

const ROUTE = 'course/module';

export const orderSave = async (filters = null) => {
  const TOKEN = store.getters['auth/userToken'];

  const modules = store.state.course.current.modules.map((item, index) => {
    item.position = index;
    return item;
  });

  const data = await apiUtils.post(
    `${ROUTE}/ordersave`,
    {
      course_id: store.state.course.current._id,
      modules,
    },
    TOKEN
  );
  return data.body;
};

export const save = async payload => {
  const TOKEN = store.getters['auth/userToken'];

  const data = await apiUtils.post(`${ROUTE}/save`, payload, TOKEN);
  return data.body;
};

export const remove = async payload => {
  const TOKEN = store.getters['auth/userToken'];

  const data = await apiUtils.delete(
    `${ROUTE}/remove/${payload.courseID}/${payload.moduleID}`,
    {},
    TOKEN
  );
  return data.body;
};
