<template>
  <div class="mobile-menu">
    <v-navigation-drawer v-model="drawer.isOpen" absolute temporary right>
      <div class="mobile-menu--logo">
        <header-logo :change="false" />
      </div>
      <div v-for="(item, index) in menuItems" :key="index">
        <a
          :class="[
            isActive(item.target) ? 'active' : '',
            `${item.target}`,
            'muted',
          ]"
          class="mobile-menu--anchor local"
          @click="goTo(item.target)"
          >{{ $tt.capitalize($t(item.label)) }}</a
        >
      </div>
      <hr />
      <div v-for="(item, index) in bottomItems" :key="`bi_${index}`">
        <a
          :class="[
            isActive(item.target) ? 'active' : '',
            `${item.target}`,
            'muted',
          ]"
          class="mobile-menu--anchor local"
          @click="goTo(item.target)"
          >{{ $tt.capitalize($t(item.label)) }}</a
        >
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { UserType } from '../../helpers/user_utils';
import HeaderLogo from './HeaderLogo';

export default {
  components: {
    HeaderLogo,
  },
  computed: {
    drawer() {
      return this.$store.state.system.mobileMenu;
    },
    menuItems() {
      let items = [];

      const userType = this.$store.getters['auth/user'].type;
      switch (userType) {
        case UserType.Admin:
          items = [
            { label: 'labels.courses', target: 'course-list' },
            { label: 'labels.users', target: 'user-student-list' },
            { label: 'labels.teams', target: 'team-list' },
            { label: 'labels.learningPaths', target: 'learning-paths-list' },
            { label: 'labels.reports', target: 'report-list' },
            { label: 'labels.settings', target: 'settings' },
          ];
          break;
        case UserType.TSM:
          items = [
            { label: 'labels.courses', target: 'course-list' },
            { label: 'labels.users', target: 'user-student-list' },
            { label: 'labels.teams', target: 'team-list' },
            { label: 'labels.learningPaths', target: 'learning-paths-list' },
            { label: 'labels.reports', target: 'report-list' },
          ];
          break;
        case UserType.Master:
          items = [
            { label: 'labels.courses', target: 'course-list' },
            { label: 'labels.users', target: 'user-student-list' },
            { label: 'labels.teams', target: 'team-list' },
            { label: 'labels.learningPaths', target: 'learning-paths-list' },
            { label: 'labels.reports', target: 'report-list' },
          ];
          break;
        case UserType.Student:
          items = [
            { label: 'labels.courses', target: 'course-list' },
            { label: 'labels.learningPaths', target: 'learning-paths-list' },
          ];
          break;
        default:
          items = [
            { label: 'labels.courses', target: 'course-list' },
            { label: 'labels.learningPaths', target: 'learning-paths-list' },
          ];
      }

      return items;
    },
    bottomItems() {
      return [
        { label: 'labels.notifications', target: 'notification-list' },
        { label: 'labels.profile', target: 'profile-view' },
      ];
    },
  },
  methods: {
    isActive(route) {
      try {
        var targetMeta = '',
          currentMeta = '';

        //target  meta
        const targetObjRoute = this.$router.options.routes.find(
          item => item.name == route
        );
        if (targetObjRoute.meta && targetObjRoute.meta.menu) {
          targetMeta = targetObjRoute.meta.menu;
        }

        // current meta
        currentMeta = this.$route.meta.menu;

        return currentMeta == targetMeta;
      } catch (e) {
        return false;
      }
    },
    goTo(routeName) {
      this.$router.push({
        name: routeName,
      });
    },
  },
};
</script>
