import apiUtils from '../helpers/api_utils';
import userUtils from '../helpers/user_utils';
import { i18n } from '../main';
import * as authService from '../services/auth';

const store = {
  namespaced: true,
  state: () => ({
    isLogged: false,
    // sending: false,
    user: {},
    simulatedUser: null,
  }),
  getters: {
    user({ user, simulatedUser }) {
      if (simulatedUser) return simulatedUser;
      else return user;
    },
    userId({ user }) {
      return user.id;
    },
    userToken({ user, simulatedUser }) {
      if (simulatedUser) return simulatedUser.token;
      else return user.token;
    },
  },
  mutations: {
    setUser(state, authUser) {
      state.isLogged = true;

      state.user = authUser;
      localStorage.setItem('fluke-lms-user', JSON.stringify(authUser));
    },
    set(state, user) {
      state.isLogged = true;
      state.id = user._id;
      state.token = user.token;
      state.master = user.master;
      localStorage.setItem(
        'fluke-lms-user',
        JSON.stringify({
          id: user._id,
          token: user.token,
        })
      );
    },
    logout(state) {
      state.isLogged = false;
      state.id = null;
      state.token = null;
      state.master = null;
      localStorage.removeItem('fluke-lms-user');
    },
    // setSending(state, flag) {
    //   state.sending = flag;
    // },
    setUserBack(state) {
      localStorage.setItem(
        'fluke-lms-user-back',
        JSON.stringify({
          id: state.id,
          token: state.token,
        })
      );
    },
    removeUserBack() {
      localStorage.removeItem('fluke-lms-user-back');
    },
  },
  actions: {
    getUserData({ getters }, property) {
      return getters['user'][property];
    },
    async checkLogin({ state, commit }) {
      try {
        if (localStorage.getItem('fluke-lms-user')) {
          state.isLogged = true;
          state.user = JSON.parse(localStorage.getItem('fluke-lms-user'));
          const result = await apiUtils.post('auth/check_token', {
            token: state.user.token,
          });

          commit('system/selectLang', state.user.lang, { root: true });

          /**
           * É necessário normalizar todas as respostas da API
           */
          let data;
          if (typeof result.data !== 'undefined') data = result.data;
          else data = result;

          if (!data.status) {
            commit('logout');
            if (data.message == 'expired-token') {
              commit(
                'snackbar/show',
                {
                  content: i18n.t('alerts.general.loginExpired'),
                  type: 'error',
                },
                { root: true }
              );
            }
          }
        }
      } catch (error) {
        commit('logout');
      }
      return state.isLogged;
    },
    async login({ commit }, payload) {
      try {
        const data = await apiUtils.post('auth/login', payload);

        let master = null;
        if (data.status) {
          if (
            typeof data.body.masters !== 'undefined' &&
            data.body.masters.length
          ) {
            master = data.body.masters[0]._id;
          }
          let lang = 'en';
          if (typeof data.body.lang !== 'undefined') {
            lang = data.body.lang;
          }
          const authUser = {
            id: data.body._id,
            token: data.body.token,
            type: userUtils.getType(data.body),
            master,
            lang,
          };
          commit('setUser', authUser);

          return data.body;
        }
      } catch (e) {
        return false;
      }
    },
    async loginAs({ rootGetters, state }, userID) {
      const id = userID ? userID : rootGetters['user/id'];
      const userData = await authService.getData(
        ['token', 'type', 'name', 'email'],
        id
      );

      state.simulatedUser = {
        id,
        name: userData.name,
        email: userData.email,
        type: userUtils.getType(userData),
        token: userData.token,
      };

      const body = document.querySelector('body');
      body.classList.add('simulated-user');
    },
    async logout({ commit }) {
      if (localStorage.getItem('fluke-lms-user-back')) {
        const userBack = JSON.parse(
          localStorage.getItem('fluke-lms-user-back')
        );
        commit('removeUserBack');
        commit('set', {
          _id: userBack.id,
          token: userBack.token,
        });
        return { redirect: 'dashboard' };
      } else {
        commit('logout');
        commit('user/clear', null, { root: true });
        return { redirect: 'login' };
      }
    },
    async getData({ getters }, dataList) {
      var missingData = 0;
      const user = getters['user'];
      if (dataList.indexOf('progress') == -1) {
        dataList.forEach(item => {
          if (!user[item]) missingData++;
        });
      } else {
        missingData = 1;
      }

      if (missingData !== 0) {
        const userData = await authService.getData(dataList);
        Object.keys(userData).forEach(item => {
          user[item] = userData[item];
        });
      }
    },
  },
};

export default store;
