import store from '../store';
import apiUtils from '../helpers/api_utils';

const ROUTE = 'setting';

export const get = async settingName => {
  const TOKEN = store.getters['auth/userToken'];

  return (await apiUtils.get(`${ROUTE}/get/${settingName}`, {}, TOKEN)).body;
};

export const save = async setting => {
  const TOKEN = store.getters['auth/userToken'];

  const name = setting.name;
  const value = setting.value;

  const result = await apiUtils.post(
    'setting/create-or-update',
    { name, value },
    TOKEN
  );

  return result.body;
};

export const init = async () => {
  const TOKEN = store.getters['auth/userToken'];

  const result = await apiUtils.get('setting/init', {}, TOKEN);

  return result.body;
};
