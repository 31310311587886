<template>
  <div :class="classes" @click.prevent="$emit('close')">
    <v-btn
      ref="closeButton"
      color="black"
      icon
      class="media-fullscreen__close"
      :style="buttonStyles"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <div ref="wrapper" class="media-fullscreen__wrapper">
      <div class="media-fullscreen__content">
        <img :src="url" alt="Preview" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    url: String,
    active: Boolean,
  },
  data() {
    return {
      buttonTop: 0,
      buttonLeft: 0,
    };
  },
  computed: {
    classes() {
      return {
        "media-fullscreen": true,
        "media-fullscreen--active": this.active,
      };
    },
    buttonStyles() {
      return {
        top: `${this.buttonTop}px`,
        left: `${this.buttonLeft}px`,
      };
    },
  },
  watch: {
    active: function (value) {
      if (value) {
        document.querySelector("html").classList.add("no-scroll");
        document.body.addEventListener("mousemove", this.followCursor);
        document.addEventListener("keyup", this.onKeyUp);
        this.$nextTick(() => {
          this.$refs.wrapper.scrollTo({ top: 0 });
        });
      } else {
        document.querySelector("html").classList.remove("no-scroll");
        document.body.removeEventListener("mousemove", this.followCursor);
        document.removeEventListener("keyup", this.onKeyUp);
      }
    },
  },
  methods: {
    followCursor(e) {
      this.buttonTop = e.y;
      this.buttonLeft = e.x;
    },
    onKeyUp(e) {
      if (e.key === "Escape") {
        this.$emit("close");
      }
    },
  },
};
</script>

<style lang="scss">
.media-fullscreen {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(black, 0.85);
  display: none;
  overflow: hidden;
  cursor: none;
  &--active {
    display: block;
  }
  &__wrapper {
    overflow: auto;
    height: 100%;
    padding: 40px;
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    img,
    video {
      max-width: 100%;
      display: block;
    }
  }
  &__close {
    position: absolute;
    top: 80px;
    right: 20px;
    background: #ffc107;
    cursor: none;
  }
}
</style>
