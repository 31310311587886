import store from '../store';
import apiUtils from '../helpers/api_utils';
import { i18n } from '../main';
import fileUploader from '../helpers/file_uploader';

const ROUTE = 'course/lesson';

export const save = async payload => {
  const TOKEN = store.getters['auth/userToken'];

  // upload files : begin
  if (payload.lesson.type == '1') {
    var filename;
    for (var lang in payload.lesson.content.pdf) {
      filename = await fileUploader.pdf(
        payload.lesson.content.pdf[lang],
        TOKEN
      );
      if (!filename) {
        throw new Error('Invalid file');
      }
      payload.lesson.content[lang] = filename;
    }
  }

  if (
    typeof payload.lesson.content.materials != 'undefined' &&
    payload.lesson.content.materials.length
  ) {
    for (var index in payload.lesson.content.materials) {
      for (var lang of Object.keys(payload.lesson.content.materials[index])) {
        if (
          payload.lesson.content.materials[index][lang].file &&
          typeof payload.lesson.content.materials[index][lang].file != 'string'
        ) {
          var filename = await fileUploader.material(
            payload.lesson.content.materials[index][lang].file,
            TOKEN
          );
          if (!filename) {
            throw new Error('Invalid file');
          }
          payload.lesson.content.materials[index][lang].file = filename;
        } else {
          // delete payload.lesson.content.materials[index][lang].file;
        }
      }
    }
  }
  // upload files : end

  const data = await apiUtils.post(`${ROUTE}/save`, payload, TOKEN);
  return data.body;
};

export const remove = async payload => {
  const TOKEN = store.getters['auth/userToken'];
  const data = await apiUtils.delete(`${ROUTE}/remove`, payload, TOKEN);
  return data.body;
};

export const orderSave = async payload => {
  const TOKEN = store.getters['auth/userToken'];
  return (await apiUtils.post('course/lesson/ordersave', payload, TOKEN)).body;
};
