<template>
  <div
    :class="{
      'cs-blue': $colorScheme == COLOR_SCHEMES.BLUE && layout != 'clear',
    }"
  >
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import { KEYS as COLOR_SCHEMES } from './data/templateColorSchemes';

export default {
  data: () => ({
    COLOR_SCHEMES,
  }),
  computed: {
    layout() {
      return (this.$route.meta.layout || 'default') + '-layout';
    },
  },
  watch: {
    '$store.state.user.lang': function(value) {
      if (value) {
        this.$i18n.locale = value;
        this.$vuetify.lang.current = value;
      }
    },
    $route: {
      handler(value) {
        this.checkColorScheme(value);
      },
      deep: true,
    },
  },
  mounted() {
    let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);
    if (params.get('SuperAdmin') === 'TRUE') {
      this.$store.state.system.superAdmin = true;
    }
  },
  head: {
    title: {
      inner: 'Fluke',
      separator: ' ',
      complement: ' ',
    },
    link: [
      {
        rel: 'apple-touch-icon',
        sizes: '57x57',
        href: '/img/favicons/apple-touch-icon-57x57.png',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '60x60',
        href: '/img/favicons/apple-touch-icon-60x60.png',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '72x72',
        href: '/img/favicons/apple-touch-icon-72x72.png',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '76x76',
        href: '/img/favicons/apple-touch-icon-76x76.png',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '114x114',
        href: '/img/favicons/apple-touch-icon-114x114.png',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '120x120',
        href: '/img/favicons/apple-touch-icon-120x120.png',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '144x144',
        href: '/img/favicons/apple-touch-icon-144x144.png',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '152x152',
        href: '/img/favicons/apple-touch-icon-152x152.png',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '180x180',
        href: '/img/favicons/apple-touch-icon-180x180.png',
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '32x32',
        href: '/img/favicons/favicon-32x32.png',
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '192x192',
        href: '/img/favicons/android-chrome-192x192.png',
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '96x96',
        href: '/img/favicons/favicon-96x96.png',
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '16x16',
        href: '/img/favicons/favicon-16x16.png',
      },
      {
        rel: 'mask-icon',
        color: '#ffc20e',
        href: '/img/favicons/safari-pinned-tab.svg',
      },
      {
        rel: 'manifest',
        href: '/img/favicons/manifest.json',
      },
    ],
    meta: [
      {
        name: 'msapplication-TileColor',
        content: '#da532c',
      },
      {
        name: 'msapplication-TileImage',
        content: '/img/favicons/mstile-144x144.png',
      },
      {
        name: 'theme-color',
        content: '#ffc20e',
      },
    ],
  },
  created() {
    this.checkColorScheme(this.$route);
  },
  methods: {
    checkColorScheme(value) {
      var colorScheme = this.COLOR_SCHEMES.YELLOW;
      if (typeof value.meta.colorScheme != 'undefined') {
        colorScheme = value.meta.colorScheme;
      }
      this.$store.commit('system/setTemplateColorScheme', colorScheme);
      this.$colorScheme = this.$store.getters['system/colorScheme'];
    },
  },
};
</script>
