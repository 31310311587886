<template>
  <div class="show-line">
    <v-list-item-icon>
      <v-icon
        color="black"
      >
        {{ notification.status == 'read' ? 'mdi-email-open' : 'mdi-email' }}
      </v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title
        class="text-uppercase"
        v-text="$i18n.t(notification.title)"
      ></v-list-item-title>
      <v-list-item-subtitle
        v-text="notification.describe"
      ></v-list-item-subtitle>
      <v-list-item-subtitle
        v-text="notification.createdAt"
      ></v-list-item-subtitle>
    </v-list-item-content>
  </div>
</template>

<script>
export default {
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.show-line {
  display: contents;
}
</style>

