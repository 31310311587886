import apiUtils from '../helpers/api_utils';
import fileUploader from '../helpers/file_uploader';
import userUtils, { UserListStatus, UserType } from '../helpers/user_utils';
import * as usersService from '../services/users';
import { KEYS as userTabsOptions } from '../data/userTabsFilterOptions';

const store = {
  namespaced: true,
  state: () => ({
    id: null,
    view: {},
    list: [],
    tabActiveType: userTabsOptions.STUDENTS,
    listCount: 0,
    current: {
      dialCode: '',
      progress: ''
    },
  }),
  getters: {
    id({ id }) {
      return id;
    },
    current({ current }) {
      return current;
    },
  },
  mutations: {
    setID(state, ID) {
      state.id = ID;
    },
    setCurrent(state, user) {
      state.current = user;
    },
    clearCurrent(state) {
      state.current = {};
    },
  },
  actions: {
    // get values of a list of properties and set to state.current
    async getData({ state }, dataList) {
      return await usersService.getData(state.current._id, dataList);
    },
    async saveData({ state }) {
      await usersService.saveData(state.current);
    },
    async accept({ rootGetters, rootState, state }) {
      const token = rootGetters['auth/userToken'];
      const data = await apiUtils.post(
        'user/accept',
        {
          userID: rootState.auth.user.id,
        },
        token
      );

      return data;
    },
    async create({ rootGetters, commit }, user) {
      const token = rootGetters['auth/userToken'];
      const data = await apiUtils.post('user/create', user, token);
      return data;
    },
    async update({ state, rootGetters, commit }, payload = null) {
      const token = rootGetters['auth/userToken'];
      let user = state.view;
      if (!payload.profile) delete user.avatar;
      if (payload && payload.profile) user = payload;
      if (user.avatar) {
        const filename = await fileUploader.avatar(user.avatar, token);
        user.avatar = filename;
      }
      const data = await apiUtils.post('user/update', user, token);
      return data;
    },
    async list({ state, commit }, filters) {
      commit('system/loading', true, { root: true });
      const data = await usersService.list(filters);

      state.list = data.list;
      state.listCount = data.paging;

      commit('system/loading', false, { root: true });
    },
    async getPendingUserCount({ state, commit }, filters) {
      return await usersService.getPendingUserCount();
    },
    async get({ rootGetters, state }, userID) {
      let _userID;
      if (!userID) {
        _userID = state.id;
      } else {
        _userID = userID;
      }
      // commit("globals/loadMode", true, { root: true });
      const token = rootGetters['auth/userToken'];
      const data = await apiUtils.get(`user/get/${_userID}`, {}, token);
      // commit("globals/loadMode", false, { root: true });

      if (data.status) {
        const user = data.body;
        if (typeof user.type == 'undefined') {
          user.type = userUtils.getType(user);
        }
        if (user.type == UserType.Student && user.masters.length) {
          user.master = user.masters[0]._id;
        }
        state.view = user;
        state.current = user;
        return true;
      }

      return false;
    },
    async registerAttempt({ rootState, rootGetters }, payload) {
      const token = rootGetters['auth/userToken'];
      const user = rootGetters['auth/user'];
      const data = await apiUtils.post(
        'user/test/register_attempt',
        {
          ids: {
            course: payload.ids.course,
            module: payload.ids.module,
            lesson: payload.ids.lesson,
            user: user.id,
          },
          userAnswers: payload.userAnswers,
        },
        token
      );

      return data;
    },
    async checkModuleTest({ rootGetters }, ids) {
      const token = rootGetters['auth/userToken'];
      const data = await apiUtils.post(
        'user/test/check_module',
        {
          course: ids.course,
          module: ids.module,
        },
        token
      );
      return data.body;
    },
    // async recoveryPassword({ commit }, email) {
    //   // commit("setSending", true);
    //   const data = await apiUtils.put('user/recovery_password', { email });
    //   // commit("setSending", false);
    //   return data.status;
    // },
    async generate_reset_token({ commit }, email) {
      commit('system/loading', true, { root: true });
      try {
        const data = await apiUtils.post('user/generate_reset_token', { email })
        if (data.status) {
          localStorage.setItem('lmsreset', JSON.stringify({
            resetToken: data.body.token
          }));
          return data.body;
        }
      } catch (error) {
        return false;
      }
      commit('system/loading', false, { root: true });
    },
    async verify_code({ commit }, payload) {
      commit('system/loading', true, { root: true });
      try {
        let userData = JSON.parse(localStorage.getItem('lmsreset'));
        const data = await apiUtils.get('user/verify_code',
          { 'pin': payload.resetToken },
          {},
          {
            headers: {
              'token': userData.resetToken,
            },
          }
        );
        if (data.status) return data.body
      } catch (error) {
        return false;
      }
      commit('system/loading', false, { root: true });
    },
    async changePassword({ commit }, payload) {
      commit('system/loading', true, { root: true });
      try {
        let userData = JSON.parse(localStorage.getItem('lmsreset'));
        const data = await apiUtils.put('user/change_password', payload, {}, {
          headers: {
            'token': userData.resetToken,
          },
        });
        if (data.status) return data.body;
      } catch (error) {
        return false;
      }
      commit('system/loading', false, { root: true });
    },
    async generateCertificate({ rootGetters }, payload) {
      const token = rootGetters['auth/userToken'];
      const data = await apiUtils.post(
        'user/certificate/generate',
        payload,
        token
      );
      return data.body;
    },
    async register({ }, payload) {
      const data = await apiUtils.post('user/register', payload);
      return data;
    },
    async remove({ rootGetters }, id) {
      const token = rootGetters['auth/userToken'];
      const data = await apiUtils.delete(`user/${id}`, {}, token);
      return data.body;
    },
    async courses({ rootGetters }, { userID, status }) {
      const token = rootGetters['auth/userToken'];
      const params = {};
      if (status) {
        params.status = status;
      }
      const data = await apiUtils.get(`user/${userID}/courses`, params, token);
      return data.body;
    },
    async teams({ rootGetters }, { userID }) {
      const token = rootGetters['auth/userToken'];
      const data = await apiUtils.get(`user/${userID}/teams`, {}, token);
      return data.body;
    },
    async getAllClean({ rootGetters, state, commit }) {
      commit('system/loading', true, { root: true });

      const token = rootGetters['auth/userToken'];
      const data = await apiUtils.get(`user/all`, {}, token);
      state.list = data.body;

      commit('system/loading', false, { root: true });
    },
    async getTSM({ rootGetters }, userID) {
      const token = rootGetters['auth/userToken'];
      const data = await apiUtils.get(`user/tsm/${userID}`, {}, token);
      return data.body;
    },
    async updateCourseProgress({ }, payload) {
      const data = await usersService.updateCourseProgress(payload)
      return data.body
    }
  },
};

export default store;
