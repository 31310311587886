<template>
  <div class="lms-breadcrumbs">
    <v-icon class="home" @click="goTo({ name: 'course-list' })"
      >mdi-home</v-icon
    >
    <v-icon class="divider">mdi-chevron-right</v-icon>

    <div v-for="(item, index) in prevLinks">
      <div class="link" v-if="item.route" @click="goTo(item.route)">
        {{ $tt.capitalize(item.title) }}
      </div>
      <div class="notlink" v-else>{{ $tt.capitalize(item.title) }}</div>
      <v-icon class="divider">mdi-chevron-right</v-icon>
    </div>

    <div class="current">
      {{ $tt.capitalize(currentTitle) }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    prevLinks: Array,
    currentTitle: String,
  },
  methods: {
    goTo(routeData) {
      this.$router.push(routeData);
    },
  },
};
</script>
