<template>
  <div class="lms-login-as" v-if="show">
    <div class="content">
      Você está vendo o sistema como:<br />
      {{ user.name }}<br />
      {{ user.email }}
    </div>
    <div class="close" @click="close">
      <v-icon>mdi-close-circle-outline</v-icon>
    </div>
  </div>
</template>

<script lang="js">
export default {
  computed: {
    show() {
      return this.user !== null
    },
    user() {
      return this.$store.state.auth.simulatedUser
    }
  },
	methods: {
		close(){
      this.$store.state.auth.simulatedUser = null;

      const body = document.querySelector("body");
      body.classList.remove("simulated-user");

			if(this.$route.name == 'course-list'){
        window.location.reload()
      } else {
        this.$router.push({
          name: 'course-list'
        })
      }
		}
	}
}
</script>

<style lang="scss" scoped>
.lms-login-as {
  background-color: rgba(255, 0, 0, 0.5);
  border-bottom-left-radius: 10px;
  padding: 12px 24px;
  text-align: right;
  display: inline-block;
  position: fixed;
  z-index: 100;
  right: 0;
  top: 64px;

  .content {
    float: left;
    line-height: 18px;
    padding-right: 24px;
  }

  .close {
    float: right;
    padding-top: 2px;
    i {
      font-size: 52px;
    }
  }
}
</style>
