import apiUtils from '../helpers/api_utils';

const ROUTE = 'learning_paths';

const store = {
  namespaced: true,
  state: () => ({
    list: [],
    data: {},
    teams: [],
    courses: [],
    loadingListData: false,
    loadingTeams: false,
    loadingCourses: false,
    sending: false,
  }),
  mutations: {
    setList(state, list) {
      state.list = list;
    },
    setData(state, data) {
      state.data = data;
    },
    setTeams(state, teams) {
      state.teams = teams;
    },
    setCourses(state, courses) {
      state.courses = courses;
    },
    setLoadingListData(state, value) {
      state.loadingListData = value;
    },
    setLoadingTeams(state, value) {
      state.loadingTeams = value;
    },
    setLoadingCourses(state, value) {
      state.loadingCourses = value;
    },
    setSending(state, flag) {
      state.sending = flag;
    },
  },
  getters: {
    getList: ({ list }) => list,
    getData: ({ data }) => data,
    getTeams: ({ teams }) => teams,
    getCourses: ({ courses }) => courses,
    getLoadingListData: ({ loadingListData }) => loadingListData,
    getLoadingTeams: ({ loadingTeams }) => loadingTeams,
    getLoadingCourses: ({ loadingCourses }) => loadingCourses,
    getSending({ sending }) {
      return sending;
    },
  },
  actions: {
    async create({ commit, rootGetters }, payload) {
      commit('setSending', true);
      const token = rootGetters['auth/userToken'];
      const result = await apiUtils.post(`${ROUTE}/create`, payload, token);
      commit('setSending', false);
      return +result.status === 1;
    },
    async update({ commit, rootGetters }, payload) {
      commit('setSending', true);
      const token = rootGetters['auth/userToken'];
      const result = await apiUtils.put(
        `${ROUTE}/update/${payload._id}`,
        payload,
        token
      );
      commit('setSending', false);
      return +result.status === 1;
    },
    async get({ rootGetters, commit }, id) {
      commit('system/loading', true, { root: true });

      const token = rootGetters['auth/userToken'];
      const data = await apiUtils.get(`${ROUTE}/get/${id}`, {}, token);

      commit('system/loading', false, { root: true });

      return data.body;
    },
    async remove({ rootGetters }, id) {
      const token = rootGetters['auth/userToken'];
      const data = await apiUtils.delete(`${ROUTE}/${id}`, {}, token);
      return data.body || [];
    },
    async loadList({ commit, rootGetters }) {
      commit('setLoadingListData', true);
      const token = rootGetters['auth/userToken'];
      const data = await apiUtils.get(`${ROUTE}/list`, {}, token);
      commit('setList', data.body);
      commit('setLoadingListData', false);
      return data.body;
    },
    async loadTeams({ commit, rootGetters }, filters = {}) {
      commit('setLoadingTeams', true);
      const token = rootGetters['auth/userToken'];
      const data = await apiUtils.post(`${ROUTE}/teams`, filters, token);
      commit('setTeams', data.body);
      commit('setLoadingTeams', false);
      return data.body;
    },
    async loadCourses({ commit, rootGetters }) {
      commit('setLoadingCourses', true);
      const token = rootGetters['auth/userToken'];
      const data = await apiUtils.get('course/list', {}, token);
      commit('setCourses', data.body.list);
      commit('setLoadingCourses', false);
      return data.body;
    },
    async clearTeams({ commit }) {
      commit('setTeams', []);
    },
  },
};

export default store;
