export default [
  {
    path: '/profile',
    name: 'profile-view',
    component: function() {
      return import(
        /* webpackChunkName: "profile-view" */ '../views/profile/View.vue'
      );
    },
  },
  {
    path: '/profile/privacy',
    name: 'profile-privacy',
    component: function() {
      return import(
        /* webpackChunkName: "profile-privacy" */ '../views/profile/Privacy.vue'
      );
    },
  },
  {
    path: '/profile/language',
    name: 'profile-language',
    component: function() {
      return import(
        /* webpackChunkName: "profile-language" */ '../views/profile/Language.vue'
      );
    },
  },
  {
    path: '/profile/data',
    name: 'profile-data',
    component: function() {
      return import(
        /* webpackChunkName: "profile-data" */ '../views/profile/PersonalData.vue'
      );
    },
  },
];
