import apiUtils from "../helpers/api_utils"

const store = {
  namespaced: true,
  state: () => ({
    rating: []
  }),
  actions: {
    async setRating({ rootGetters }, payload) {
      const token = rootGetters['auth/userToken']
      const data = await apiUtils.post('rating/set-rating/', payload, token)
      return data.body || []
    }
  }
}

export default store
