import { render, staticRenderFns } from "./FormBtnHolder.vue?vue&type=template&id=2fba8a73&scoped=true"
var script = {}
import style0 from "./FormBtnHolder.vue?vue&type=style&index=0&id=2fba8a73&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fba8a73",
  null
  
)

export default component.exports