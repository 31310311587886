import { isLinear } from '../services/courses';
import * as examService from '../services/exam';

const store = {
  namespaced: true,
  state: () => ({
    currentScreen: examService.screenNames.Loading,
    attempts: {
      has: false,
      lastScore: 0,
      currentScore: 0,
      qty: 0,
      timeControl: {
        waitTimers: {
          hours: 0,
          minutes: 0,
          seconds: 0,
        },
        timeLocked: false,
      },
    },
    systemSettings: {
      maxAttemptsNumber: 0,
      timeBetweenAttempts: 0, // in hours
    },
    locks: {
      tooMany: false,
      tooSoon: false,
      meetRequirementPreModules: false,
    },
    currentRunIsFinished: false,
    examQuestionsData: {
      showExam: false,
      questions: [],
      attempts: [],
      userAnswers: {},
      showingAnswers: false,
      validForm: false,
      run: {
        corrects: 0,
        progress: 0,
      },
    },
  }),
  getters: {
    currentScreen: ({ currentScreen }) => currentScreen,
    getFullInfo: ({ attempts }) => attempts,
    lastScoreInfo: ({ attempts }, _getters, _rootState, rootGetters) => {
      const lesson = rootGetters['lesson/current'];
      const min = lesson.data.min_questions;
      const max = lesson.data.questions.length;

      return {
        has: attempts.has,
        lastScore: attempts.lastScore,
        min,
        max,
        approved: attempts.lastScore >= min,
        perc: (100 * attempts.lastScore) / max,
      };
    },
    attemptsCountInfo: ({ attempts, systemSettings }) => {
      return {
        current: attempts.qty,
        max: systemSettings.maxAttemptsNumber,
      };
    },
    attemptsTimeControlInfo: ({ attempts, systemSettings }) => {
      return {
        hoursSinceLast: attempts.hoursSinceLast,
        min: systemSettings.timeBetweenAttempts,
      };
    },
  },
  mutations: {
    setScreen(state, name) {
      state.currentScreen = name;
    },
  },
  actions: {
    evaluateDataAndSetScreen(
      { state, rootGetters, dispatch },
      setScreen = true
    ) {
      let screen = examService.screenNames.Start;

      const lesson = rootGetters['lesson/current'];
      const min = lesson.data.min_questions;
      const attempts = state.attempts;
      const approved = attempts.lastScore >= min;

      if (approved) {
        screen = examService.screenNames.ApprovedLock;
      } else if (!state.locks.meetRequirementPreModules) {
        screen = examService.screenNames.RequirementLock;
      } else if (
        // attempts quantity control
        state.systemSettings.maxAttemptsNumber > 0 &&
        state.attempts.qty >= state.systemSettings.maxAttemptsNumber
      ) {
        state.locks.tooMany = true;
        screen = examService.screenNames.AttemptsLock;
      } else if (
        // time control
        state.systemSettings.timeBetweenAttempts > 0 &&
        state.attempts.timeControl.timeLocked
      ) {
        state.locks.tooSoon = true;
        screen = examService.screenNames.TimeLock;
      }

      if (setScreen) {
        state.currentScreen = screen;
      }

      console.log('screen');
      dispatch('lesson/expandTest', {}, { root: true });
    },
    async loadInfoData(
      { state, commit, dispatch, rootGetters },
      setScreen = true
    ) {
      state.currentScreen = examService.screenNames.Loading;

      let ids = {
        course: rootGetters['course/current']._id,
        module: rootGetters['module/current']._id,
        lesson: rootGetters['lesson/current']._id,
      };
      const fullInfo = await examService.loadInfoData(ids);

      state.attempts.has = fullInfo.attempts.has;
      state.attempts.lastScore = fullInfo.attempts.lastScore;
      state.attempts.qty = fullInfo.attempts.qty;
      state.attempts.timeControl = fullInfo.attempts.timeControl;

      state.systemSettings.maxAttemptsNumber =
        fullInfo.system.maxAttemptsNumber;
      state.systemSettings.timeBetweenAttempts =
        fullInfo.system.timeBetweenAttempts;

      state.locks.meetRequirementPreModules =
        fullInfo.meetRequirementPreModules;

      dispatch('evaluateDataAndSetScreen', setScreen);
    },
    async openExam({ state, rootGetters }) {
      const lesson = rootGetters['lesson/current'];
      state.examQuestionsData.questions = [...lesson.data.questions];
      state.currentScreen = examService.screenNames.Exam;
    },

    async saveAttempt({ state, dispatch, rootGetters }) {
      const lesson = rootGetters['lesson/current'];
      state.currentScreen = examService.screenNames.Loading;

      // register attempt
      const currentRoute = rootGetters['system/currentRoute'];
      const { body: result } = await dispatch(
        'user/registerAttempt',
        {
          ids: {
            course: currentRoute.params.courseid,
            module: currentRoute.params.moduleid,
            lesson: currentRoute.params.lessonid,
          },
          userAnswers: state.examQuestionsData.questions.map(question => {
            return {
              questionID: question.id,
              answers: [
                state.examQuestionsData.userAnswers[question.id],
              ].flat(),
            };
          }),
        },
        { root: true }
      );
      // cleaning
      state.examQuestionsData.userAnswers = {};
      state.examQuestionsData.showingAnswers = false;

      state.attempts.currentScore = result.score;

      // update progress
      let ids;
      if (isLinear()) {
        ids = {
          course: currentRoute.params.courseid,
          lesson: currentRoute.params.lessonid,
        };
      } else {
        ids = {
          course: currentRoute.params.courseid,
          module: currentRoute.params.moduleid,
          lesson: currentRoute.params.lessonid,
        };
      }
      await dispatch(
        'progress/update',
        {
          current: result.score,
          progress: 100,
          ids,
        },
        { root: true }
      );

      // reload exam info
      await dispatch('loadInfoData', false);

      // reload user info
      const user = rootGetters['auth/user'];
      delete user.certificates;
      delete user.examAttempts;
      await dispatch('auth/getData', ['certificates', 'examAttempts'], {
        root: true,
      });
      dispatch('reloadSidebar');

      state.currentRunIsFinished = true;

      state.attempts.currentScore >= lesson.data.min_questions
        ? (state.currentScreen = examService.screenNames.ApprovedLock)
        : (state.currentScreen = examService.screenNames.Result);
    },
    reloadSidebar() {
      // do not remove, for subscription purposes only
    },
    clearData({ state }) {
      state.currentScreen = examService.screenNames.Loading;
      state.attempts = {
        has: false,
        lastScore: 0,
        currentScore: 0,
        qty: 0,
        timeControl: {
          waitTimers: {
            hours: 0,
            minutes: 0,
            seconds: 0,
          },
          timeLocked: false,
        },
      };
      state.systemSettings = {
        maxAttemptsNumber: 0,
        timeBetweenAttempts: 0, // in hours
      };
      state.locks = {
        tooMany: false,
        tooSoon: false,
      };
      state.currentRunIsFinished = false;
      state.examQuestionsData = {
        showExam: false,
        questions: [],
        attempts: [],
        userAnswers: {},
        showingAnswers: false,
        validForm: false,
        run: {
          corrects: 0,
          progress: 0,
        },
      };
    },
  },
};

export default store;
