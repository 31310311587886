export default [
  {
    path: "/teams",
    name: "team-list",
    component: function() {
      return import(
        /* webpackChunkName: "team-list" */ "../views/team/List.vue"
      );
    }
  },
  {
    path: "/team/create",
    name: "team-create",
    component: function() {
      return import(
        /* webpackChunkName: "team-create" */ "../views/team/CreateEdit.vue"
      );
    }
  },
  {
    path: "/team/edit/:id",
    name: "team-edit",
    component: function() {
      return import(
        /* webpackChunkName: "team-edit" */ "../views/team/CreateEdit.vue"
      );
    }
  },
  {
    path: "/team/view/:id",
    name: "team-view",
    component: function() {
      return import(
        /* webpackChunkName: "team-view" */ "../views/team/View.vue"
      );
    }
  }
].map(item => {
  if (!item["meta"]) {
    item["meta"] = { menu: "teams" };
  }
  return item;
});
