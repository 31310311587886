import { get, save as settingSave } from './settings';

export const save = async values => {
  const result = settingSave({
    name: 'training_types',
    value: JSON.stringify(values.map(item => item.title)),
  });
  return result.body;
};

export const list = async () => {
  const result = await get('training_types');

  const resultArray = JSON.parse(result);

  // id for facilitate things
  return resultArray.map((item, index) => ({
    id: index,
    title: item,
  }));
};
