import apiUtils from '../helpers/api_utils';
import { UserType } from '../helpers/user_utils';

const store = {
  namespaced: true,
  state: () => ({
    id: null,
    list: [],
    view: {
      masters: [],
      leaders: [],
      members: [],
      name: '',
      status: 'disabled',
      users: [],
    },
    firstLoad: true,
    data: {},
    masters: [],
    leaders: [],
    students: [],
    loadingListData: false,
    loadingMasters: false,
    loadingStudents: false,
    sending: false,
    loadingUsers: false,
  }),
  getters: {
    getList: ({ list }) => list,
    getData: ({ data }) => data,
    getMasters: ({ masters }) => masters,
    getLeaders: ({ view }) => view.leaders,
    getUsers: ({ view }) => view.users,
    getLoadingListData: ({ loadingListData }) => loadingListData,
    getLoadingMasters: ({ loadingMasters }) => loadingMasters,
    getLoadingStudents: ({ loadingStudents }) => loadingStudents,
    getSending: ({ sending }) => sending,
  },
  mutations: {
    setList(state, list) {
      state.list = list;
    },
    setData(state, data) {
      state.data = data;
    },
    setMasters(state, masters) {
      state.masters = masters;
    },
    setStudents(state, students) {
      state.students = students;
    },
    setLoadingListData(state, value) {
      state.loadingListData = value;
    },
    setLoadingMasters(state, value) {
      state.loadingMasters = value;
    },
    setLoadingStudents(state, value) {
      state.loadingStudents = value;
    },
    setSending(state, value) {
      state.sending = value;
    },
  },
  actions: {
    async loadList({ commit, rootGetters }) {
      commit('setLoadingListData', true);
      const token = rootGetters['auth/userToken'];
      const data = await apiUtils.get('team/list', {}, token);
      commit('setList', data.body);
      commit('setLoadingListData', false);
      return data.body;
    },
    async create({ state, rootGetters }) {
      const token = rootGetters['auth/userToken'];
      const data = await apiUtils.post(
        'team/create',
        {
          masters: state.view.masters,
          members: state.view.members,
          leaders: state.view.leaders,
          name: state.view.name,
          status: state.view.status,
        },
        token
      );
      const success = +data.status === 1;
      return success;
    },
    async update({ state, commit, dispatch, rootGetters }, payload) {
      let data;
      commit('setSending', true);
      const token = rootGetters['auth/userToken'];
      if (typeof payload != 'undefined') {
        data = await apiUtils.put(
          `team/update/${payload._id}`,
          {
            status: payload.status,
          },
          token
        );
      } else {
        data = await apiUtils.put(
          `team/update/${state.id}`,
          {
            masters: state.view.masters,
            members: state.view.members,
            leaders: state.view.leaders,
            name: state.view.name,
            status: state.view.status,
          },
          token
        );
      }
      commit('setSending', false);
      dispatch('clear');
      const success = +data.status === 1;
      return success;
    },
    async get({ commit, rootGetters, state }, teamID) {
      commit('system/loading', true, { root: true });
      state.id = teamID;

      const token = rootGetters['auth/userToken'];
      const { status, body } = await apiUtils.get(
        `team/get/${teamID}`,
        {},
        token
      );
      const success = +status === 1;
      if (success) {
        state.view.name = body.name;
        state.view.masters = body.masters;
        state.view.leaders = body.leaders;
        state.view.members = body.members;
        state.view.status = body.status;
        commit('setData', body);
      }
      commit('setLoadingListData', false);
      commit('system/loading', false, { root: true });
      return success;
    },
    async remove({ commit, rootGetters }, id) {
      commit('setSending', true);
      const token = rootGetters['auth/userToken'];
      const data = await apiUtils.delete(`team/remove/${id}`, {}, token);
      commit('setSending', false);
      const success = +data.status === 1;
      return success;
    },
    async loadUsers({ state, rootGetters, commit }, masters) {
      if (!state.firstLoad) {
        state.view.leaders = [];
        state.view.members = [];
        state.view.users = [];

        if (!masters.length) {
          state.loadingUsers = false;
          return false;
        }
      } else {
        state.firstLoad = false;
      }

      const token = rootGetters['auth/userToken'];

      const data = await apiUtils.post('team/load-users', { masters }, token);

      data.body.forEach(item => {
        state.view.users.push(item);
      });

      state.loadingUsers = false;
      return true;
    },
    clear({ state, rootState }) {
      const currentRoute = rootState.system.currentRoute;
      if (currentRoute && currentRoute.name == 'team-edit') {
        return;
      }
      state.id = null;
      state.view = {
        masters: [],
        leaders: [],
        members: [],
        name: '',
        status: 'disabled',
        users: [],
      };
    },
    async clearStudents({ commit }) {
      commit('setStudents', []);
    },
  },
};

export default store;
