const store = {
  namespaced: true,
  state: () => ({
    content: '',
    type: '',
  }),
  mutations: {
    show(state, payload) {
      state.content = payload.content
      state.type = payload.type
    }
  },
}

export default store
