import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mobile-menu"},[_c(VNavigationDrawer,{attrs:{"absolute":"","temporary":"","right":""},model:{value:(_vm.drawer.isOpen),callback:function ($$v) {_vm.$set(_vm.drawer, "isOpen", $$v)},expression:"drawer.isOpen"}},[_c('div',{staticClass:"mobile-menu--logo"},[_c('header-logo',{attrs:{"change":false}})],1),_vm._l((_vm.menuItems),function(item,index){return _c('div',{key:index},[_c('a',{staticClass:"mobile-menu--anchor local",class:[
          _vm.isActive(item.target) ? 'active' : '',
          `${item.target}`,
          'muted',
        ],on:{"click":function($event){return _vm.goTo(item.target)}}},[_vm._v(_vm._s(_vm.$tt.capitalize(_vm.$t(item.label))))])])}),_c('hr'),_vm._l((_vm.bottomItems),function(item,index){return _c('div',{key:`bi_${index}`},[_c('a',{staticClass:"mobile-menu--anchor local",class:[
          _vm.isActive(item.target) ? 'active' : '',
          `${item.target}`,
          'muted',
        ],on:{"click":function($event){return _vm.goTo(item.target)}}},[_vm._v(_vm._s(_vm.$tt.capitalize(_vm.$t(item.label))))])])})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }