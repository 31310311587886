const store = {
  namespaced: true,
  state: () => ({
    content: '',
  }),
  mutations: {
    showAlert(state, payload) {
      state.content = payload.content
    },
    clearAlert() {
      //
    }
  },
}

export default store
