<template>
  <div @click="click" class="lms-page-header-action-btn">
    <div class="lms-phab-icon">
      <v-icon>{{ icon }}</v-icon>
    </div>
    <div class="lms-phab-text">
      <slot />
    </div>
  </div>
</template>

<script lang="js">
export default {
  props: {
    icon: String
  },
  methods: {
    click(){
      this.$emit('click')
    }
  }
}
</script>
