const store = {
  namespaced: true,
  state: () => ({
    content: '',
    title: '',
    accept: false,
    cancel: false,
    resolve: false,
    type: 'prompt',
  }),
  actions: {
    show({ state }, payload) {
      state.content = payload.content;
      state.title = payload.title;
      state.accept = payload.accept ? payload.accept : false;
      state.cancel = payload.cancel ? payload.cancel : false;
      state.type = payload.alert ? 'alert' : 'prompt';

      return new Promise((resolve, reject) => {
        state.resolve = resolve;
      });
    },
    resolve({ state }, response) {
      state.resolve(response);
      state.resolve = null;
      state.type = 'prompt';
    },
  },
};

export default store;
