import * as reportService from '../services/report';
import apiUtils from '../helpers/api_utils';

const store = {
  namespaced: true,
  state: () => ({
    downloading: false,
    courseList: [],
    courseItemsCount: 0,
    courseView: {},
    studentList: [],
    studentItemsCount: 0,
    studentView: {},
  }),
  mutations: {
    setDownloading(state, value) {
      state.downloading = value;
    },
    setCourseList(state, list) {
      state.courseList = list;
    },
    setCourseItemsCount(state, count) {
      state.courseItemsCount = count;
    },
    setCourseView(state, view) {
      state.courseView = view;
    },
    setStudentList(state, list) {
      state.studentList = list;
    },
    setStudentItemsCount(state, count) {
      state.studentItemsCount = count;
    },
    setStudentView(state, view) {
      state.studentView = view;
    },
  },
  actions: {
    // Courses actions: BEGIN
    async courseList(
      { state, commit },
      options = {
        clean: false,
      }
    ) {
      commit('system/loading', true, { root: true });
      const result = await reportService.courseList(options);

      state.courseList = result.list;
      state.courseItemsCount = result.paging.count;
      commit('system/loading', false, { root: true });
    },
    async courseView({ state, commit }, id) {
      state.courseView = {};
      commit('system/loading', true, { root: true });
      const result = await reportService.courseView(id);
      state.courseView = result;
      commit('system/loading', false, { root: true });
    },
    async downloadCourse({ state, commit }, options) {
      const result = await reportService.downloadCourse(options);
      return result;
    },
    // END

    // Students actions: BEGIN
    async studentList(
      { state, commit },
      options = {
        clean: false,
      }
    ) {
      commit('system/loading', true, { root: true });
      const result = await reportService.studentList(options);

      state.studentList = result.list;
      state.studentItemsCount = result.paging.count;
      commit('system/loading', false, { root: true });
    },
    async studentData({ state, commit }, id) {
      state.studentView = {};
      commit('system/loading', true, { root: true });
      const result = await reportService.studentView(id);
      state.studentView = result;
      commit('system/loading', false, { root: true });
    },
    async downloadStudent({ rootGetters, commit }, filters) {
      commit('setDownloading', true);
      const token = rootGetters['auth/userToken'];
      const data = await apiUtils.get(
        'report/student-download',
        filters,
        token,
        {
          responseType: 'blob',
        }
      );
      commit('setDownloading', false);
      return data;
    },
    // END

    // async loadCourseListTeam({ rootGetters, commit }, options = {}) {
    //   commit('setLoading', true);
    //   commit('setCourseList', []);
    //   commit('setCourseItemsCount', 0);
    //   const token = rootGetters['auth/userToken'];
    //   const data = await apiUtils.get(
    //     'report/course-list-team',
    //     options,
    //     token
    //   );
    //   commit('setCourseList', data.body.list);
    //   commit('setCourseItemsCount', data.body.paging.count);
    //   commit('setLoading', false);
    // },
    // async loadCourseListLearningPaths({ rootGetters, commit }, options = {}) {
    //   commit('setLoading', true);
    //   commit('setCourseList', []);
    //   commit('setCourseItemsCount', 0);
    //   const token = rootGetters['auth/userToken'];
    //   const data = await apiUtils.get(
    //     'report/course-list-learning-paths',
    //     options,
    //     token
    //   );
    //   commit('setCourseList', data.body.list);
    //   commit('setCourseItemsCount', data.body.paging.count);
    //   commit('setLoading', false);
    // },
    // async loadCourseView({ rootGetters, commit }, options = {}) {
    //   commit('system/loading', true, { root: true });

    //   commit('setLoading', true);
    //   commit('setCourseView', {});
    //   const token = rootGetters['auth/userToken'];
    //   const data = await apiUtils.get(
    //     'report/course-view/' + options.id,
    //     options,
    //     token
    //   );
    //   commit('setCourseView', data.body);
    //   commit('setLoading', false);

    //   commit('system/loading', false, { root: true });
    // },
    // async loadStudentList({ rootGetters, commit }, options = {}) {
    //   commit('setLoading', true);
    //   commit('setStudentList', []);
    //   commit('setStudentItemsCount', 0);
    //   const token = rootGetters['auth/userToken'];
    //   const data = await apiUtils.get('report/student-list', options, token);
    //   commit('setStudentList', data.body.list);
    //   commit('setStudentItemsCount', data.body.paging.count);
    //   commit('setLoading', false);
    // },
    // async loadStudentListTeam({ rootGetters, commit }, options = {}) {
    //   commit('setLoading', true);
    //   commit('setStudentList', []);
    //   commit('setStudentItemsCount', 0);
    //   const token = rootGetters['auth/userToken'];
    //   const data = await apiUtils.get(
    //     'report/student-list-team',
    //     options,
    //     token
    //   );
    //   commit('setStudentList', data.body.list);
    //   commit('setStudentItemsCount', data.body.paging.count);
    //   commit('setLoading', false);
    // },
    // async loadStudentListLearningPaths({ rootGetters, commit }, options = {}) {
    //   commit('setLoading', true);
    //   commit('setStudentList', []);
    //   commit('setStudentItemsCount', 0);
    //   const token = rootGetters['auth/userToken'];
    //   const data = await apiUtils.get(
    //     'report/student-list-learning-paths',
    //     options,
    //     token
    //   );
    //   commit('setStudentList', data.body.list);
    //   commit('setStudentItemsCount', data.body.paging.count);
    //   commit('setLoading', false);
    // },
    // async downloadStudents({ rootGetters, commit }, filters) {
    //   commit('setDownloading', true);
    //   const token = rootGetters['auth/userToken'];
    //   const data = await apiUtils.get(
    //     'report/student-download',
    //     filters,
    //     token,
    //     {
    //       responseType: 'blob',
    //     }
    //   );
    //   commit('setDownloading', false);
    //   return data;
    // },
    // async downloadCourseTeam({ rootGetters, commit }, filters) {
    //   commit('setDownloading', true);
    //   const token = rootGetters['auth/userToken'];
    //   const data = await apiUtils.get(
    //     'report/course-team-download',
    //     filters,
    //     token,
    //     {
    //       responseType: 'blob',
    //     }
    //   );
    //   commit('setDownloading', false);
    //   return data;
    // },
    // async downloadCourseLearningPath({ rootGetters, commit }, filters) {
    //   commit('setDownloading', true);
    //   const token = rootGetters['auth/userToken'];
    //   const data = await apiUtils.get(
    //     'report/course-learningpath-download',
    //     filters,
    //     token,
    //     {
    //       responseType: 'blob',
    //     }
    //   );
    //   commit('setDownloading', false);
    //   return data;
    // },
  },
};

export default store;
