export default [
  {
    path: '/users',
    name: 'user-list',
    component: function () {
      return import(
        /* webpackChunkName: "user-list" */ '../views/user/List.vue'
      );
    },
  },
  {
    path: '/users/student',
    name: 'user-student-list',
    component: function () {
      return import(
        /* webpackChunkName: "user-student-list" */ '../views/user/List.vue'
      );
    },
  },
  {
    path: '/users/fact',
    name: 'user-fact-list',
    component: function () {
      return import(
        /* webpackChunkName: "user-fact-list" */ '../views/user/List.vue'
      );
    },
  },
  {
    path: '/users/tsm',
    name: 'user-tsm-list',
    component: function () {
      return import(
        /* webpackChunkName: "user-tsm-list" */ '../views/user/List.vue'
      );
    },
  },
  {
    path: '/users/admin',
    name: 'user-admin-list',
    component: function () {
      return import(
        /* webpackChunkName: "user-admin-list" */ '../views/user/List.vue'
      );
    },
  },
  {
    path: '/users/master',
    name: 'user-master-list',
    component: function () {
      return import(
        /* webpackChunkName: "user-student-list" */ '../views/user/List.vue'
      );
    },
  },
  {
    path: '/user/create',
    name: 'user-create',
    component: function () {
      return import(
        /* webpackChunkName: "user-create" */ '../views/user/CreateEdit.vue'
      );
    },
  },
  {
    path: '/user/edit/:id',
    name: 'user-edit',
    component: function () {
      return import(
        /* webpackChunkName: "user-edit" */ '../views/user/CreateEdit.vue'
      );
    },
  },
  {
    path: '/user/view/:id/profile',
    name: 'user-view-profile',
    component: function () {
      return import(
        /* webpackChunkName: "user-view-profile" */ '../views/user/View.vue'
      );
    },
  },
  {
    path: '/user/view/:id/courses',
    name: 'user-view-courses',
    component: function () {
      return import(
        /* webpackChunkName: "user-view-courses" */ '../views/user/View.vue'
      );
    },
  },
  {
    path: '/user/view/:id/teams',
    name: 'user-view-teams',
    component: function () {
      return import(
        /* webpackChunkName: "user-view-teams" */ '../views/user/View.vue'
      );
    },
  },
  // {
  //   path: "/user/view/:id/:tab?",
  //   name: "user-view",
  //   component: function() {
  //     return import(
  //       /* webpackChunkName: "user-edit" */ "../views/user/View.vue"
  //     );
  //   }
  // },
].map(item => {
  if (!item['meta']) {
    item['meta'] = { menu: 'user' };
  }
  return item;
});
