<template>
  <div @click="open" class="mobile-menu-btn">
    <v-icon>mdi-menu</v-icon>
  </div>
</template>

<script>
export default {
  methods: {
    open() {
      this.$store.state.system.mobileMenu.isOpen = true;
    },
  },
};
</script>
