import apiUtils from '../helpers/api_utils';
import {
  get as getCountry,
  list as listCountries,
} from '../services/countries';

// @TODO: criar o store locations
const store = {
  namespaced: true,
  state: () => ({
    list: [],
    sending: false,
    current: null,
  }),
  getters: {
    getSending: ({ sending }) => sending,
    current: ({ current }) => current,
  },
  mutations: {
    setList(state, list) {
      state.list = list;
    },
    setSending(state, flag) {
      state.sending = flag;
    },
    clearCurrent(state) {
      state.current = null;
    },
  },
  actions: {
    async loadList({ commit }, filters = {}) {
      const data = await listCountries(filters);
      commit('setList', data);
    },
    async listStates({ commit }, _id) {
      const data = await apiUtils.get('country/listStates', { _id });
      return data.body;
    },
    async listCities({ commit }, payload) {
      const data = await apiUtils.get('country/listCities', payload);
      return data.body;
    },
    async getDialCode({ commit }, _id) {
      const data = await apiUtils.get('country/getDialCode', { _id });
      return data.body;
    },
    async get({ state }, countryID) {
      state.current = await getCountry(countryID);
    },
  },
};

export default store;
