<template>
  <v-app class="lms">
    <mobile-menu />
    <f-app-bar />
    <f-login-as-bar />
    <v-main class="body">
      <slot />
    </v-main>
    <snackbar />
    <f-dialog />
    <f-media-fullscreen
      :url="mediaFullscreen.url"
      :active="mediaFullscreen.active"
      @close="onCloseMediaFullscreen"
    />
  </v-app>
</template>

<script>
import snackbar from '../components/shared/Snackbar';
import dialog from '../components/shared/GlobalDialog';
import MediaFullscreen from '../components/shared/MediaFullscreen.vue';
import FAppBar from '../components/Layouts/AppBar.vue';
import FLoginAsBar from '../components/shared/LoginAsBar.vue';
import MobileMenu from '../components/Layouts/MobileMenu.vue';

export default {
  components: {
    snackbar,
    'f-dialog': dialog,
    'f-media-fullscreen': MediaFullscreen,
    FAppBar,
    FLoginAsBar,
    MobileMenu,
  },
  computed: {
    mediaFullscreen() {
      return {
        url: this.$store.getters['media/getURL'],
        active: this.$store.getters['media/isActive'],
      };
    },
  },
  methods: {
    onCloseMediaFullscreen() {
      this.$store.dispatch('media/closeModal');
    },
  },
};
</script>

<style lang="scss">
@import '../assets/scss/global';
header {
  .product {
    font-size: 12px;
    margin-right: 10px;
  }
  .appbar-separator {
    margin-right: 10px;
  }
}
.no-scroll {
  overflow: hidden;
}
</style>
