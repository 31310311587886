import * as courseService from '../services/courses';

const store = {
  namespaced: true,
  state: () => ({
    current: {},
  }),
  getters: {
    current({ current }) {
      return current;
    },
  },
  mutations: {
    clear(state) {
      state.current = {};
    },
  },
  actions: {
    async save({ state, rootGetters }) {
      const course = rootGetters['course/current'];
      await courseService.modules.save({
        courseID: course._id,
        module: state.current,
      });
    },
    async remove({ rootGetters }, moduleID) {
      const course = rootGetters['course/current'];
      await courseService.modules.remove({
        courseID: course._id,
        moduleID,
      });
    },
    select({ state, rootGetters }, moduleID) {
      const course = rootGetters['course/current'];
      const module = course.modules.find(mdl => mdl._id == moduleID);
      state.current = module;
    },
  },
};

export default store;
