<template>
  <div
    class="lms-card-title"
    @click="click"
    :class="{ clickable: hasClickEvent }"
  >
    <slot name="icon" />
    <div class="content" :class="{ small: $props.small }">
      <slot />
    </div>
    <div v-if="hasActionsSlot" class="actions">
      <slot name="actions" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasActionsSlot() {
      return !!this.$slots['actions'];
    },
    hasClickEvent() {
      return typeof this._events.click != 'undefined';
    },
  },
  methods: {
    click() {
      this.$emit('click');
    },
  },
};
</script>
