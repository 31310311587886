export default [
  {
    path: '/learning-paths',
    name: 'learning-paths-list',
    component: function() {
      return import(
        /* webpackChunkName: "learning-paths-list" */ '../views/learningPaths/List.vue'
      );
    },
  },
  {
    path: '/learning-paths/create',
    name: 'learning-paths-create',
    component: function() {
      return import(
        /* webpackChunkName: "learning-paths-create" */ '../views/learningPaths/CreateEdit.vue'
      );
    },
  },
  {
    path: '/learning-paths/edit/:id',
    name: 'learning-paths-edit',
    component: function() {
      return import(
        /* webpackChunkName: "learning-paths-edit" */ '../views/learningPaths/CreateEdit.vue'
      );
    },
  },
  {
    path: '/learning-paths/view/:id',
    name: 'learning-paths-view',
    component: function() {
      return import(
        /* webpackChunkName: "learning-paths-view" */ '../views/learningPaths/View.vue'
      );
    },
  },
  {
    path: '/learning-paths/admin/view/:id',
    name: 'learning-paths-reports',
    component: function() {
      return import(
        /* webpackChunkName: "learning-paths-reports" */ '../views/learningPaths/admin/View.vue'
      );
    },
  },
].map(item => {
  if (!item['meta']) {
    item['meta'] = { menu: 'learnigPaths' };
  }
  return item;
});
