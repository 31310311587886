const textTranform = {
  capitalize(text) {
    try {
      return text.charAt(0).toUpperCase() + text.slice(1);
    } catch (e) {
      return text;
    }
  },
  uppercase(text) {
    return text.toUpperCase();
  },
};

export default {
  install(Vue, options) {
    Vue.prototype.$tt = textTranform;
  },
};
