export default [
  {
    path: "/login",
    name: "login",
    component: function () {
      return import(/* webpackChunkName: "login" */ "../views/login/Login.vue");
    }
  },
  {
    path: "/recovery",
    name: "recovery",
    component: function () {
      return import("../views/login/Recovery.vue")
    }
  },
  {
    path: "/verify-code",
    name: "verify-code",
    component: function () {
      return import("../views/login/VerifyCode.vue")
    }
  },
  {
    path: "/reset",
    name: "login-reset",
    component: function () {
      return import(/* webpackChunkName: "reset" */ "../views/login/Reset.vue");
    }
  },
  {
    path: "/register",
    name: "login-register",
    component: function () {
      return import(
        /* webpackChunkName: "reset" */ "../views/login/Register.vue"
      );
    }
  },
  {
    path: "/privacy-policy",
    name: "login-privacy",
    component: function () {
      return import(
        /* webpackChunkName: "reset" */ "../views/login/PrivacyPolicy"
      );
    },
    meta: {
      layout: "default"
    }
  }
].map(item => {
  if (!item["meta"]) {
    item["meta"] = { layout: "login" };
  }
  return item;
});
