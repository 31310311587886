import store from '../store/index';

export default {
  install(Vue, options) {
    Vue.directive('allowed', {
      inserted(el, binding, vnode, old) {
        if (!binding.value.includes(store.getters['auth/user'].type)) {
          vnode.elm.parentElement.removeChild(vnode.elm);
        }
      },
    });
  },
};
