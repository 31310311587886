export default {
  alerts: {
    general: {
      buttons: {
        cancel: 'cancelar',
        accept: 'sim',
      },
      successCreate: 'Criado com sucesso!',
      errorCreate: 'Algum campo foi preenchido incorretamente',
      successEdit: 'Editado com sucesso!',
      errorEdit: 'Erro ao editar',
      answerSelectError: 'Selecionar a resposta correta',
      verifyAnswers: 'Criar no mínimo 2 respostas',
      verifyLanguages: 'Check fields for another languages',
      leastOneQuestion: 'You need to create at least one question',
      errorToDownload: 'Erro ao fazer download',
      loginExpired: 'Sessão expirada',
      pendindUsers: 'usuario(s) aguardando aprovação',
    },
    login: {
      errorPassword: 'Email inválido/senha incorreta!',
      errorRecovery: 'Email inválido',
      errorCode: 'Código inválido',
    },
    courses: {
      llSaveOrder: 'A ordem será salva em breve!',
      successSavedOrder: 'Ordem Salva!',
      remove: {
        title: 'Remover',
        content: 'Você deseja remover este curso?',
      },
      removeModule: {
        title: 'Remover',
        content: 'Você deseja remover este módulo?',
      },
      youNeedWait:
        'Você precisa aguardar {count} {label} para tentar novamente.',
      minToPass:
        'Para receber o certificado você precisa passar {min}% do teste.',
      manyTries: 'Muitas tentativas, por favor contacte o seu master.',
      beforeExam:
        'Você precisa completar todos os módulos anteriores para fazer este exame.',
      removeQuestion: {
        title: 'Aviso',
        content: 'Você tem certeza?',
      },
      removeAnswer: {
        title: 'Aviso',
        content: 'Você tem certeza?',
      },
      removeMaterial: {
        title: 'Aviso',
        content: 'Você tem certeza?',
      },
    },
    users: {
      remove: {
        title: 'Remover',
        content: 'Você deseja remover este usuário?',
      },
      notFound: 'User not found',
      errorSaved: 'Por favor revise seu formulário antes de salvar',
    },
    teams: {
      remove: {
        title: 'Remover',
        content: 'Você deseja remover este time?',
      },
      notFound: 'Team not found',
    },
    learningPaths: {
      remove: {
        title: 'Remover',
        content: 'Você deseja remover esta trilha de aprendizado?',
      },
    },
    profile: {
      languageUpdade: {
        success: 'Idioma do sistema trocado com sucesso',
      },
      resetPassword: {
        title: 'Aviso',
        content: 'Você deseja redefinir sua senha?',
      },
    },
    report: {
      noDataToExport: 'Não há dados para serem exportados',
    },
    settings: {
      globalLabel: {
        title: 'Aviso',
        content: 'Você adicionar um Global Label a todos os usuários e cursos?',
        success: {
          title: 'Sucesso',
          content: 'Global Label adicionados com sucesso',
        },
      },
      initSettings: {
        title: 'Aviso',
        content: 'Você deseja iniciar o DB com as definições padrões?',
        success: {
          title: 'Sucesso',
          content: 'As definições foram atualizadas com sucesso',
        },
      },
      clearDBCache: {
        title: 'Aviso',
        content: 'Você deseja limpar o cachê do DB?',
        success: {
          title: 'Sucesso',
          content: 'O cache do DB foi limpo com sucesso',
        },
      },
      fixInvalidDate: {
        title: 'Aviso',
        content:
          'Deseja corrigir datas inválidas para usuários que concluíram os cursos?',
        success: {
          title: 'Sucesso',
          content: 'Datas inválidas corrigidas com sucesso',
        },
      },
      master: {
        removeDistributor: {
          title: 'Aviso',
          content: 'Você deseja remover este distribuidor? Ao remover o distribuidor todos os usuários vinculados serão desabilitados.',
        }
      }
    },
  },
  labels: {
    accept: 'Aceito',
    actions: 'Ações',
    addAnswer: 'Adicionar resposta',
    addLesson: 'Adicionar lição',
    addMaterial: 'Adicionar material',
    addModule: 'Adicionar módulo',
    addQuestion: 'Adicionar questão',
    addDistribuitor: 'Adicionar distribuidor',
    admin: 'Admin',
    all: 'Todos',
    allCourses: 'Todos cursos',
    answer: 'Resposta',
    answerList: 'Lista de respostas:',
    answerListEmpty: 'Lista de respostas vazia',
    attempts: 'Tentativas',
    approved: 'Aprovado',
    back: 'voltar',
    backToCourse: 'voltar para o curso',
    backToCourses: 'voltar para cursos',
    backToLearningPaths: 'voltar para trilhas de aprendizado',
    backToTeam: 'voltar para time',
    backToTeams: 'voltar para times',
    backToUsers: 'voltar para usuários',
    cancel: 'cancelar',
    certificateLayout: 'Modelo do Certificado',
    change: 'Modificar',
    championSince: 'Champion Desde',
    city: 'cidade',
    city: 'Cidade',
    close: 'Fechar',
    country: 'país',
    country: 'País',
    courses: 'Cursos',
    create: 'criar',
    createAccount: 'Criar conta',
    createCourse: 'criar curso',
    createdAt: 'Criado em',
    createLearningPaths: 'criar trilha de aprendizado',
    certificateGenerator: 'Gerador de certificados',
    createModule: 'Criar módulo',
    customDate: 'Data personalizada',
    createTeam: 'Criar time',
    createUser: 'Usuário criado',
    dashboard: 'painel de controle',
    description: 'descrição',
    dialCode: 'DDI',
    dialCode: 'Código Internacional de Telefone',
    disabled: 'Desabilitado',
    disableds: 'Desabilitados',
    distributor: 'Distribuidor',
    done: 'Concluído',
    downloadCertificate: 'Baixar certificado',
    fact: 'FACT',
    edit: 'editar',
    edit: 'Editar',
    editCourse: 'editar curso',
    editLesson: 'editar lição',
    editedWithSuccess: 'Editado com sucesso!',
    editLearningPaths: 'editar trilha de aprendizado',
    editModule: 'Editar módulo',
    editTeam: 'Editar time',
    editUser: 'editar usuário',
    email: 'email',
    email: 'Email',
    en: 'English',
    enabled: 'Habilitado',
    enableds: 'Habilitados',
    endCourse: 'Terminar curso',
    enrollmentDate: 'Data de Inscrição',
    errorLoadAnswers: 'Erro ao carregar respostas',
    es: 'español',
    exam: 'teste',
    examAnswers: 'Respostas da Prova',
    examAttempts: 'Tentativas de exame',
    exportXLS: 'Exportar XLS',
    file: 'Arquivo',
    fileWillBeRemove: 'O arquivo será removido ao aplicar',
    filter: 'Filtro',
    filterAdmins: 'Admins',
    filterLocale: 'Idioma',
    filterMasters: 'Masters',
    filterStatus: 'Status',
    filterStudents: 'Estudantes',
    filterTSM: 'TSMs',
    flukeAcademyID: 'Fluke Academy ID',
    forgot: 'Esqueceu a senha?',
    generateCertificate: 'Gerar certificado após conclusão do exame',
    generateCertificateAtTheEnd: 'Gerar certificado ao final do curso',
    image: 'Imagem',
    insufficientScore: 'Pontuação insuficiente',
    isOptionalModule: 'Módulo opcional',
    hours: 'Horas',
    language: 'Idioma',
    languages: 'Idiomas',
    lastAttempt: 'Última tentativa',
    lessons: 'lições',
    learningPaths: 'trilhas de aprendizado',
    learningPath: 'trilha de aprendizado',
    LMSInfo: 'Informações LMS',
    loading: 'Carregando...',
    locationData: 'Endereço',
    login: 'login',
    loginAsUser: 'Fazer login como este usuário',
    logout: 'Sair',
    master: 'Master',
    master: 'Master',
    masters: 'Masters',
    materials: 'Materiaais',
    masterDistributor: 'Master / Distribuidor',
    materialName: 'Nome do material',
    maxAttempsNumbers:
      'Você esgotou as {attemptsNumber} tentativas disponíveis para esse teste.',
    minimumGrade: 'Nota mínima',
    moduleDescription: 'Descrição do Módulo',
    moduleMarkAsRead: 'Marcar módulo como visualizado',
    moduleWasRead: 'Concluído',
    myProgress: 'Meu Progresso',
    myCertificates: 'Meus Certificados',
    name: 'nome',
    new: 'Novo',
    newCourse: 'Novo curso',
    newLearningPath: 'nova trilha de aprendizado',
    newTeam: 'Novo time',
    next: 'próximo',
    nextLesson: 'Próxima lição',
    noAttempt: 'Você não possui mais tentativas',
    noLearningPaths: 'Você não possui Trilhas de Aprendizado',
    noTeams: 'Você ainda não faz parte de um time',
    notifications: 'notificações',
    others: 'outros',
    pending: 'Pendentes',
    password: 'senha',
    pdf: 'PDF',
    pdf: 'PDF',
    personalData: 'Dados Pessoais',
    personalInfo: 'Informações Pessoais',
    phone: 'telefone',
    phone: 'Telefone',
    post: 'Texto',
    privacy: 'Política de Privacidade',
    profile: 'Perfil',
    profilePicture: 'Foto do Perfil',
    progress: 'Em progresso',
    pt: 'português',
    qty: 'Quantidade',
    question: 'Pergunta',
    questionList: 'Questionário:',
    questionListEmpty: 'Questionário vazio',
    remainig: 'restantes',
    remove: 'Remover',
    rejected: 'Rejeitado',
    rejecteds: 'Rejeitados',
    reports: 'relatórios',
    requireCompletedModulesBeforeExam:
      'Requer lições concluídas antes do exame',
    reset: 'Resetar',
    recovery: 'Recuperar senha',
    resetPassword: 'redefinir senha',
    resetPassword: 'Redefinir senha',
    result: 'Resultado',
    restartExam: 'refazer exame',
    startExam: 'Começar Exame',
    save: 'salvar',
    saveAnswer: 'Salvar resposta',
    saveQuestion: 'SALVAR QUESTÃO',
    search: 'Busca',
    seeAnswers: 'Ver gabarito',
    selectAll: 'selecionar todos',
    send: 'Enviar',
    settings: 'definições',
    state: 'Estado',
    states: 'estados',
    status: {
      disabled: 'Desativado',
      done: 'Finalizado',
      draft: 'Rascunho',
      enabled: 'Ativado',
      in_progress: 'Em andamento',
      pending: 'Pendente',
      published: 'Publicado',
      rejected: 'Rejeitado',
      undefined: 'Indefinido',
    },
    student: 'Estudante',
    students: 'Estudantes',
    structure: "Estrutura do curso",
    supportMaterial: 'Material de apoio',
    teamLeader: 'Líder | Líderes',
    teamMembers: 'Membros do time',
    teamMembersCount: 'estudante | estudantes',
    teamName: 'Nome do time',
    team: 'time',
    teams: 'Times',
    title: 'título',
    trainingCategory: 'Categorias do Treinamento',
    try: 'Tentativa',
    tryAgain: 'Tentar novamente',
    tsm: 'TSM',
    trainingType: 'Tipo de treinamento',
    trainingTypes: 'Tipo de treinamentos',
    TSMCategory: 'Categoría TSM',
    TSMCountry: 'País TSM',
    TSMInfo: 'Informação TSM',
    TSMRegion: 'Região TSM',
    type: 'modelo',
    userCategory: 'Categoria do usuario',
    userClassification: 'Classificação do usuario',
    users: 'usuários',
    userStatus: 'Status do usuário',
    userType: 'Tipo de usuário',
    value: 'valor',
    video: 'video',
    vimeoID: 'Vimeo video ID',
    welcomeCreatePass:
      'Bem-vindo à nova plataforma! Como esse é seu primeiro acesso, enviamos um link para seu e-mail para que você cria uma nova senha de acesso.',
    youNeddChooseTheRightAnswer: 'Escolha a resposta correta',
    youNeddTwoQuestion: 'Mínimo necessário de 2 questões!',
    youNeedOneQuestion: 'Mínimo de uma questão!',
  },
  rules: {
    allLangsField: 'É necessário preencher em todos os idiomas',
    required: 'Obrigatório',
  },
  texts: {
    dash: {
      dev: 'em desenvolvimento',
    },
  },
  settings: {
    names: {
      functions: 'funçṍes',
      customCertificate: 'criar certificado',
      addGlobalLabel: 'adicionar etiqueta global',
      initGlobalDef: 'Iniciar definições gerais',
      clearDBCache: 'Limpar cache de dados',
      data: 'dados',
      config: 'configurações',
      time_wait_exam_tries:
        'Tempo de espera entre tentativas de exame (em horas)',
      max_exam_tries: 'Máximo de tentativas de exame',
      sendgrid_api_key: 'Sendgrid API Key',
      mail_from: 'Remetente dos e-mails',
      site_url: 'URL do site',
      api_url: 'URL da API',
      trainingType: 'tipo de treinamento',
      userCategory: 'categorias de usuários',
      countryDist: 'países/distribuidores',
      fixInvalidDate: 'Corrigir data inválida de Cursos Concluídos',
      checkUsers: 'Verificar Usuários',
    },
  },
  loginPage: {
    accept: {
      text: 'Eu aceito as ',
      link: 'políticas de privacidade',
    },
    country: 'País',
    state: 'Estado',
    email: 'Email',
    back: 'voltar',
    name: 'Nome Completo',
    password: 'Senha',
    passwordConfirmation: 'Confirmação de senha',
    country: 'País',
    master: 'Master',
    langs: {
      portuguese: 'Português',
      english: 'English',
      spanish: 'Español',
    },
    pages: {
      recovery: {
        title: 'Recuperar senha',
        disclaimer: 'Digite seu endereço de e-mail abaixo e enviaremos instruções para redefinir sua senha.',
      },
      verifyCode: {
        title: 'Verificar código',
        disclaimer: 'Digite o código de verificação que enviamos para o seu e-mail.',
      },
      forgot: {
        title: 'Esqueceu a senha?',
        text: 'Digite seu email para receber um link e criar uma nova senha',
        invalidEmail: 'Email inválido',
        success: 'Link enviado para o seu email! Verifique em alguns minutos',
        error: 'Conta não encontrada',
      },
      reset: {
        title: 'Mudar senha',
        text: 'Criar nova senha',
        newPass: 'Nova senha',
        confirmPass: 'Confirmação de senha',
        invalidPassword: 'Mínimo de 6 caracteres',
        invalidConfirmation: 'Senha inválida!',
        goToLogin: 'Ir para página de acesso',
        success: 'Senha modificada!',
        error: 'Link inválido',
      },
      register: {
        title: 'Criar conta',
        text: 'Criar sua conta',
        success:
          'Registro enviado com sucesso! Vamos processar seu registo, em breve você receberá o acesso',
        error: 'Não foi possível registrar, por favor, tente novamente!',
        errorDuplicated:
          'Não foi possível registrar, este email já está registrado!',
        goToLogin: 'Ir para página de acesso',
        invalids: {
          name: 'Nome inválido',
          email: 'Email Inválido',
          password: 'Senha inválida',
          country: 'País invalido',
          state: 'Estado inválido',
          master: 'Master inválido',
          passwordConfirmation: 'Confirmação de senha inválida',
          passwordConfirmationDifferent: 'As senhas não coincidem',
        },
      },
    },
  },
  reports: {
    back: 'voltar em relatórios',
    course: {
      course: 'Curso',
      studentsCount: 'Total de estudantes',
      studentsProgress: 'Estudantes em andamento',
      studentsDone: 'Estudantes finalizado',
      name: 'Nome',
      progress: 'Progresso',
      done: 'Finalizado',
      totalStudents: 'Total de estudantes',
      doneStudents: 'Finalizado',
      doneDate: 'Data de finalização',
    },
    student: {
      name: 'Nome',
      coursesCount: 'Cursos em andamento',
      coursesDone: 'Cursos finalizado',
      totalCourses: 'Total de cursos',
      doneCourses: 'Finalizado',
      course: 'Curso',
      progress: 'Progresso',
      done: 'Finalizado',
      doneDate: 'Data de finalização',
      certificateError: 'Erro ao gerar certificado',
    },
  },
  notifications: {
    clear: 'Limpar notificações',
    default: 'Tudo certo por aqui',
    course: {
      add: 'Um novo curso foi adicionado',
      update: 'Um curso foi atualizado',
    },
    linearLesson: {
      add: 'Uma nova lição foi adicionada',
      update: 'Uma lição foi atualizada',
    },
    module: {
      add: 'Um novo módulo foi adicionada',
      update: 'Um módulo foi atualizada',
    },
    moduleLesson: {
      add: 'Uma nova lição foi adicionada',
      update: 'Uma lição foi atualizada',
    },
    team: {
      add: 'Você foi adicionado a um time',
      update: 'Um time que você faz parte foi atualizado',
    },
    learningPath: {
      add: 'Você foi adicionado a uma trilha de aprendizado',
      update: 'Uma trilha de aprendizado que você faz parte foi atualizada',
    },
  },
  rating: {
    title: 'Visão Geral',
    subtitle: 'Avaliações',
  },
  users: {
    edit: {
      alertMissing:
        'Faltam alguns dados importantes no seu perfil, preencha-os!',
    },
    view: {
      filterCourses: 'Cursos',
      filterTeams: 'Times',
      filterActivities: 'Atividades Recentes',
      filterConquests: 'Conquistas',
      statusLabel: 'Status',
      team: 'Equipe',
      leader: 'Líder',
      yes: 'Sim',
      activity: 'Atividade',
      createdAt: 'Data',
      activityType: 'Tipo',
      activiteTypes: {
        course: 'Conquista',
        module: 'Módulo',
        user: 'Pessoa',
      },
      activities: {
        course:
          '<strong>{name}</strong> ganhou uma nova conquista <strong>{course}</strong>',
        module:
          '<strong>{name}</strong> concluiu o módulo <strong>{module}</strong>',
        user:
          'A conta de <strong>{name}</strong> foi atualizada por <strong>{updatedBy}</strong>',
      },
    },
  },
  apiStatus: {
    '7': 'Esse e-mail já possui cadastro em outro país',
  },
  profile: {
    options: {
      personalData: 'Dados Pessoais',
      language: 'Idioma',
      resetPassword: 'Resetar senha',
      logout: 'Sair',
    },
  },
};
