import apiUtils from '../helpers/api_utils';

const store = {
  namespaced: true,
  state: () => ({
    selected: {
      current: 0,
      progress: 0,
    },
    list: null,
  }),
  mutations: {
    selectModule(state, moduleID) {
      if (!state.list) {
        state.selected.current = 0;
        state.selected.progress = 0;
        return;
      }
      var currentData = state.list.find(mdl => mdl.moduleID == moduleID);
      if (typeof currentData == 'undefined') {
        state.selected.current = 0;
        state.selected.progress = 0;
        return;
      }
      state.selected.progress = currentData.progress;
      state.selected.current = currentData.current;
    },
  },
  actions: {
    async update({ state, rootState, rootGetters }, payload) {
      if (
        state.selected.progress < payload.progress ||
        !state.selected.progress
      ) {
        state.selected.progress = payload.progress;
      }

      const payloadMark = {
        ids: {
          user: rootState.auth.user.id,
          course: payload.ids.course,
          module: payload.ids.module,
          lesson: payload.ids.lesson,
        },
        data: {
          progress: state.selected.progress,
          current: payload.current,
        },
      };
      const token = rootGetters['auth/userToken'];
      const data = await apiUtils.post(
        'user/progress/mark',
        payloadMark,
        token
      );

      rootState.auth.user.progress = data.body.progress;

      if (data && +data.status === 1) {
        const { body } = data;
        const progressModules = body.progress
          .map(item => {
            return item.modules;
          })
          .flat();
        state.list = (state.list || []).map(item => {
          const dataItem = progressModules.find(_item => {
            return _item.moduleID === item.moduleID;
          });
          if (dataItem) {
            return { ...dataItem };
          }
          return { ...item };
        });
      }
      return state;
    },
    async loadCourseProgress({ state, rootState, rootGetters }, course) {
      const token = rootGetters['auth/userToken'];
      const payload = {
        ids: {
          user: rootState.auth.user.id,
          course,
        },
      };
      const data = await apiUtils.post('user/progress/course', payload, token);
      state.list = data.body;
      return state.list;
    },
    async forceUpdateExam({ rootGetters }, payload) {
      const token = rootGetters['auth/userToken'];
      await apiUtils.post('user/progress/force_update_exam', payload, token);
    }
  },
};

export default store;
