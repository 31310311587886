import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"global-dialog"},[_c(VDialog,{attrs:{"width":"500"},model:{value:(_vm.showContent),callback:function ($$v) {_vm.showContent=$$v},expression:"showContent"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.$store.state.dialog.title)+" ")]),_c(VCardText,[_vm._v(" "+_vm._s(_vm.$store.state.dialog.content)+" ")]),_c(VDivider),_c(VCardActions,[_c(VSpacer),(_vm.$store.state.dialog.type == 'prompt')?_c(VBtn,{attrs:{"elevation":"0","text":""},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$store.state.dialog.cancel === false ? _vm.$t('alerts.general.buttons.cancel') : _vm.$store.state.dialog.cancel)+" ")]):_vm._e(),_c(VBtn,{attrs:{"elevation":"0","color":"primary"},on:{"click":_vm.confirm}},[_vm._v(" "+_vm._s(_vm.$store.state.dialog.accept === false ? _vm.$t('alerts.general.buttons.accept') : _vm.$store.state.dialog.accept)+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }