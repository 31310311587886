import * as lessonService from '../services/courseLessons';
import * as ratingService from '../services/ratings';
import apiUtils from '../helpers/api_utils';

const store = {
  namespaced: true,
  state: () => ({
    list: [],
    current: {},

    nextScreen: false,
    isLast: false,
  }),
  getters: {
    current({ current }) {
      return current;
    },
  },
  mutations: {
    clear(state) {
      state.current = {};
    },
  },
  actions: {
    async save({ state, rootGetters, rootState }) {
      if (state.current.type == '2') {
        state.current.content = {
          questions: rootState.questions.list,
          min_questions: rootState.questions.min,
        };
      }

      const { _id: moduleID } = rootGetters['module/current'];
      const { _id: courseID } = rootGetters['course/current'];
      await lessonService.save({
        lesson: state.current,
        courseID,
        moduleID,
      });
    },
    async remove({ state, rootGetters }, lessonID) {
      const { _id: moduleID } = rootGetters['module/current'];
      const { _id: courseID } = rootGetters['course/current'];
      lessonService.remove({
        lessonID,
        courseID,
        moduleID,
      });
    },
    async select({ state, rootGetters }, lessonID) {
      const course = rootGetters['course/current'];
      const { _id: moduleID } = rootGetters['module/current'];
      const isLinear = rootGetters['course/isLinear'];
      let lesson, module;
      if (isLinear) {
        lesson = course.modules.find(mdl => mdl._id == lessonID);

        state.isLast =
          course.modules.findIndex(mdl => mdl._id == lessonID) + 1 ==
          course.modules.length;
      } else {
        module = course.modules.find(mdl => mdl._id == moduleID);
        lesson = module.lessons.find(item => item._id == lessonID);

        state.isLast =
          module.lessons.findIndex(mdl => mdl._id == lessonID) + 1 ==
          module.lessons.length;
      }
      state.current = lesson;
    },
    async checkModuleTest({ rootState, rootGetters }, ids) {
      const TOKEN = rootGetters['auth/userToken'];
      // console.log(TOKEN, ids);
      // console.log('weeee');
      let payload;
      if (rootGetters['course/isLinear']) {
        payload = {
          course: ids.course,
          module: ids.lesson,
        };
      } else {
        payload = {
          course: ids.course,
          module: ids.module,
          lesson: ids.lesson,
        };
      }
      const data = await apiUtils.post(
        'user/test/check_module',
        payload,
        TOKEN
      );
      return data.body;
    },
    async getLastAttempts({ rootState, rootGetters, commit }, ids) {
      const TOKEN = rootGetters['auth/userToken'];
      let payload;
      if (rootGetters['course/isLinear']) {
        payload = {
          course: ids.course,
          module: ids.lesson,
        };
      } else {
        payload = {
          course: ids.course,
          module: ids.module,
          lesson: ids.lesson,
        };
      }
      const data = await apiUtils.post(
        'user/test/get_previous_attempt',
        {
          ...payload,
          user: rootState.auth.user.id,
        },
        TOKEN
      );
      commit('system/loading', false, { root: true });
      return data.body;
    },
    async getLessonRating({ state, commit }) {
      // commit('system/loading', true, { root: true });

      let result = await ratingService.getLessonRating(state.current._id);
      if (!result) {
        return 0;
      }

      // commit('system/loading', false, { root: true });

      return result;
    },
    async module_download_support_material({ rootGetters }, payload) {
      const token = rootGetters['auth/userToken'];
      const data = await apiUtils.post(
        `course/module/download-material`,
        payload,
        token
      );
      return data;
    },
    showNextLessonScreen({ state }) {
      state.nextScreen = true;
    },
    finishedLoad() {
      console.log('finishedLoad');
      // don't remove - event for listenings
    },
    expandTest() {
      console.log('expandTest');
      // don't remove - event for listenings
    },
  },
};

export default store;
