import axios from 'axios'
import store from '../store/index'

export default {
  install(Vue, options) {
    axios.interceptors.response.use(response => {
      return response;
    }, async error => {
      store.commit('snackbar/show', {
        content: 'there is something wrong',
        type: 'error'
      })
      return await error
    })
  }
}

