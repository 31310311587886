import apiUtils from './api_utils'

const courseUtils = {
  certificateFilename: (course, language) => {
    const mainLanguage = course.langs[0]
    const name = (course.title[language] || course.title[mainLanguage])
      .replace(/ /g, "-")
      .toLowerCase();
    return `${name}-cert.pdf`
  },
  progressPercentage(moduleItem, progress) {
    const moduleCount = moduleItem
      .filter((item) => !item.is_optional)
      .length
    let prog = 0;
    try {
      prog = progress
        .filter((progress) => {
          const isValid = moduleItem.find((item) => {
            return progress.moduleID === item._id
          })
          return isValid
        })
        .map((item) => item.progress)
        .reduce((a, b) => a + b, 0);
      prog = prog / moduleCount;
    } catch (e) { }
    let percent = prog;
    percent = percent.toFixed(0);
    if (isNaN(percent)) {
      percent = 0;
    }
    if (+percent > 100) {
      percent = 100
    }
    return percent;
  },
  imageURL(name) {
    return apiUtils.getURL() + `file/course/image/${name}`
  },
  courseTitle(course, locale) {
    const currentLanguage = course.langs.includes(locale)
      ? locale
      : course.langs[0];
    return course.title[currentLanguage];
  },
  moduleExamNotApproved(user, modules, courseID) {
    let userModulesExamAttempts = [], moduleExamNotApproved = [];
    let courseExamAttempts = user.examAttempts.find(exam => exam.courseID == courseID)

    if (courseExamAttempts) {
      modules.forEach(mdl => {
        let mdlAttempts = courseExamAttempts.modules.find(item => item.moduleID == mdl._id)
        if (mdlAttempts != undefined) {
          let data = {
            ...mdlAttempts,
            data: mdl.data
          }
          userModulesExamAttempts.push(data)
        }
      });
      userModulesExamAttempts.forEach(examAttempt => {
        let userModuleScore = examAttempt.list.slice(-1).length != 0 ? examAttempt.list.slice(-1).pop().score : 0;
        if (userModuleScore < examAttempt.data.min_questions) {
          moduleExamNotApproved.push(examAttempt.moduleID)
        }
      });
      return moduleExamNotApproved;
    }
  }
}

export default courseUtils
