<template>
  <div
    class="lms-card-item"
    :class="{ clickable: $props.clickable }"
    @click="click"
  >
    <div class="content">
      <slot />
    </div>

    <slot name="actions" />
  </div>
</template>

<script>
export default {
  props: {
    clickable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    click() {
      this.$emit('click');
    },
  },
};
</script>
