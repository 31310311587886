export default {
  random(length) {
    var result = '',
      characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
      charactersLength = characters.length
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result + Date.now()
  },
  convertBreakLinesToBrTag(value) {
    if (value) {
      let newValue = value
      newValue = newValue.replace(/\n/g, '<br />')
      return newValue
    }
    return ''
  },
  truncate(value, size) {
    if (value) {
      if (value.length > size) {
        return value.substr(0, size) + '...'
      }
    }
    return value
  },
}
