<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    topBottom: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        'f-container': true,
        'f-container--top-bottom': this.topBottom,
      };
    },
  },
};
</script>
