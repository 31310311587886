<template>
  <div class="courses-header-tabs">
    <a
      v-for="(item, index) in links"
      :key="index"
      @click="goTo(item.route)"
      :class="[isActiveRoute(item.route) ? 'active' : '', `a-${item.route}`]"
    >
      {{ item.label }}
    </a>
  </div>
</template>

<script>
export default {
  props: {
    links: Array,
  },
  methods: {
    isActiveRoute(routeData) {
      return this.$route.name == routeData.name;
    },
    goTo(routeData) {
      this.$router.push(routeData);
    },
  },
};
</script>
