<template>
  <div>
    <div :class="classes" :style="styles">
      <f-container>
        <div v-if="hasBreadcrumbs" class="breadcrumbs">
          <lms-breadcrumbs :currentTitle="title" :prevLinks="prevLinks" />
        </div>

        <div v-if="hasContentSlot" class="content">
          <slot name="content" />
        </div>

        <div class="title">
          <h1>{{ title }}</h1>
          <div v-if="hasActionsSlot" class="actions">
            <hr />
            <slot name="actions" />
          </div>
        </div>
      </f-container>
    </div>
    <div v-if="hasTabs" class="tabs">
      <f-container>
        <lms-page-header-tabs :links="tabs" />
      </f-container>
    </div>
    <div v-if="message" class="perm-alerts" :class="{ active: !show }">
      <f-container>
        {{ message }}
      </f-container>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    prevLinks: Array,
    background: String,
    title: String,
    tabs: Array,
  },
  data() {
    return {
      show: false,
      message: '',
    };
  },
  computed: {
    hasBreadcrumbs() {
      return !!this.$props['prevLinks'];
    },
    hasActionsSlot() {
      return !!this.$slots['actions'];
    },
    hasContentSlot() {
      return !!this.$slots['content'];
    },
    hasTabs() {
      return !!this.$props['tabs'];
    },
    classes() {
      return {
        'f-page-header': true,
        'f-page-header--with-content': this.hasContentSlot,
      };
    },
    styles() {
      if (this.background) {
        return `background-image: url("${this.background}");`;
      }
    },
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'header/showAlert') {
        this.message = state.header.content;
        this.show = true;
      } else if (mutation.type === 'header/clearAlert') {
        this.message = '';
        this.show = false;
      }
    });
  },
};
</script>
