export default [
  {
    path: "/certificate-generator",
    name: "certificate-generator",
    component: function() {
      return import(
        /* webpackChunkName: "certificate-generator" */ "../views/certificateGenerator/Index.vue"
      );
    }
  }
];
