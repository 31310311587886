// refactor
import * as courseService from '../services/courses';
import * as courseModuleService from '../services/courseModules';
import * as progressService from '../services/progress';
import apiUtils from '../helpers/api_utils';

const store = {
  namespaced: true,
  state: () => ({
    list: [],
    current: {},
    progress: {},
    courseCurrentLanguage: null,
    listTabsViewsCounters: {
      courses: '',
      inProgress: '',
      certificates: '',
    },

    // reset dialog / user list
    dialogUsers: false,
    examUsers: [],
    examAttemptsLoading: true,
    dialogLessonData: {
      lessonID: null,
    },
  }),
  mutations: {
    setID(state, id) {
      state.id = id;
    },
    clearCurrent(state) {
      state.current = {};
    },
    clearList(state) {
      state.list = [];
    },
  },
  getters: {
    current: ({ current }) => current,
    courseCurrentLanguage: ({ courseCurrentLanguage }) => courseCurrentLanguage,
    listTabsViewsCounters: ({ listTabsViewsCounters }) => listTabsViewsCounters,
    isLinear({ current }) {
      let _isLinear = false;
      if (
        typeof current.structure == 'undefined' ||
        current.structure == 'linear'
      ) {
        _isLinear = true;
      }

      return _isLinear;
    },
  },
  actions: {
    // CRUD - BEGIN
    async list(
      { state, commit, rootState },
      options = {
        clean: false,
      }
    ) {
      // commit('system/loading', true, { root: true });

      const page = !options.clean ? rootState.system.listControl.page : false;
      const result = await courseService.list({ page });
      result.list.map(course => {
        course.progress = progressService.getCourseProgress_fromFullCourse(
          course
        );
        return course;
      });

      state.list = result.list;
      rootState.system.listControl.total = Math.ceil(
        state.listTabsViewsCounters.courses / 10
      );

      // commit('system/loading', false, { root: true });
    },
    async get({ state }, id) {
      state.current = await courseService.get(id);
      state.courseCurrentLanguage = courseService.getCurrentLanguage(
        state.current
      );
    },
    async save({ state, commit }) {
      commit('system/loading', true, { root: true });

      let result;
      if (typeof state.current._id != 'undefined') {
        result = await courseService.update(state.current);
      } else {
        result = await courseService.create(state.current);
      }

      commit('system/loading', false, { root: true });
    },
    async changeStatus({ commit }, course) {
      await courseService.changeStatus(course);
    },
    async remove({}, id) {
      await courseService.remove(id);
    },
    // CRUD - END

    async ordersave({ commit, state }) {
      commit('system/loading', true, { root: true });

      courseService.orderSave(state.list);

      commit('system/loading', false, { root: true });
    },
    clear({ state }, items) {
      if (items.includes('list')) {
        state.list = [];
      }
    },
    async module_exam_list_users_attempts({ rootGetters, state }, payload) {
      state.examUsers = [];
      state.dialogUsers = true;
      state.examAttemptsLoading = true;
      state.dialogLessonData.lessonID = payload.moduleID;
      const token = rootGetters['auth/userToken'];
      const data = await apiUtils.get(
        `course/module/exam/list-users-attempts/${payload.courseID}/${payload.moduleID}`,
        {},
        token
      );
      if (+data.status === 1) {
        state.examUsers = data.body;
      }
      state.examAttemptsLoading = false;
      return data;
    },
    async module_exam_set_user_attempts({ rootGetters }, payload) {
      const token = rootGetters['auth/userToken'];
      const data = await apiUtils.put(
        `course/module/exam/set-user-attempts/${payload.courseID}/${payload.moduleID}`,
        {
          userid: payload.userID,
          attempts: payload.attempts,
        },
        token
      );
      return data;
    },
    async getListTabsViewsCounters({ state }) {
      state.listTabsViewsCounters = await courseService.headerTabsCounters();
    },
    async module_ordersave() {
      const result = await courseModuleService.orderSave();
      return result;
    },
    async module_lesson_ordersave({}, payload) {
      const result = await courseService.lessons.orderSave(payload);
      return result;
    },
    async module_exam_answers({ rootGetters }, payload) {
      const token = rootGetters['auth/userToken'];
      const data = await apiUtils.get(
        `course/module/exam/get-answers/${payload.courseID}/${payload.lessonID}?moduleID=${payload.moduleID}`,
        {},
        token
      );
      return data;
    },
    // async module_get({ rootGetters }, ids) {
    //   const token = rootGetters['auth/userToken'];
    //   const data = await apiUtils.get(
    //     `course/module/get/${ids.courseID}/${ids.moduleID}`,
    //     {},
    //     token
    //   );
    //   return data.body;
    // },
  },
};

export default store;
