import store from '../store';
import apiUtils from '../helpers/api_utils';

const ROUTE = 'user';

export const list = async (filters = null) => {
  const TOKEN = store.getters['auth/userToken'];

  let query = '';
  if (filters) query = '?' + apiUtils.jsonToQuery(filters);
  return (await apiUtils.get(`${ROUTE}/list${query}`, {}, TOKEN)).body;
};

export const getPendingUserCount = async (filters = null) => {
  const TOKEN = store.getters['auth/userToken'];

  return (await apiUtils.get(`${ROUTE}/get-pending-user-count`, {}, TOKEN))
    .body;
};

export const getData = async (userID, dataList) => {
  const TOKEN = store.getters['auth/userToken'];

  let query = '';
  query = '?' + apiUtils.arrayToQuery(dataList);
  query += '&userID=' + userID;

  return (await apiUtils.get(`${ROUTE}/get-data${query}`, {}, TOKEN)).body;
};

export const saveData = async userData => {
  const TOKEN = store.getters['auth/userToken'];

  return (await apiUtils.post(`${ROUTE}/save-data`, userData, TOKEN)).body;
};

export const getTabsCounters = async userData => {
  const TOKEN = store.getters['auth/userToken'];

  return (await apiUtils.get(`${ROUTE}/get-tabs-counters`, {}, TOKEN)).body;
};

export const courseProgress = async (userID, courseID) => {
  const TOKEN = store.getters['auth/userToken'];

  return (
    await apiUtils.get(
      `${ROUTE}/get-course-progress`,
      {
        userID,
        courseID,
      },
      TOKEN
    )
  ).body;
};

export const check = async _ => {
  const TOKEN = store.getters['auth/userToken'];

  return (await apiUtils.get(`${ROUTE}/check-all`, {}, TOKEN)).body;
};

export const updateCourseProgress = async (data) => {
  console.log(data)
  const TOKEN = store.getters['auth/userToken'];
  return (await apiUtils.post(`${ROUTE}/progress/update_course_progress`, data, TOKEN)).body
}

/**
 *
 * @param {'typeUndefined'|'invalidNullMaster'|'hasOldDefinitions'} errorType *
 * @param {string|null} userID if is not used can be null
 * @returns
 */
export const fix = async (errorType, userID) => {
  const TOKEN = store.getters['auth/userToken'];

  return (await apiUtils.get(`${ROUTE}/fix/${errorType}/${userID}`, {}, TOKEN))
    .body;
};
